import React from 'react';
import { Layout, Typography } from 'antd';

interface IContentLayout {
  children: React.ReactNode;
  title: string;
}

function ContentLayout({ children, title }: IContentLayout) {
  return (
    <Layout style={{ padding: '24px' }} className="layout">
      <Layout.Content
        className="layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Typography>
          <Typography.Title level={2} style={{ color: '#083261' }}>
            {title}
          </Typography.Title>
        </Typography>
        {children}
      </Layout.Content>
    </Layout>
  );
}

export default ContentLayout;
