import React, { useState } from 'react';
import { Button, Input, Popover, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  EyeTwoTone,
  EyeInvisibleTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';

import { ITag } from 'types';

export interface ITagTableProps {
  tags: ITag[];
  requesting: boolean;
  tagType: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  modifyTag: (param1: number, param2: boolean, param3?: string) => void;
  toggleTagVisibility: (tag:ITag) => void;
}

const TagTable = (props: ITagTableProps) => {
  const { tags, requesting, tagType, value, setValue, modifyTag, toggleTagVisibility } = props;

  const [toUpdate, setToUpdate] = useState<ITag | null>(null);

  const { push } = useHistory();

  const DeleteContent = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        type="primary"
        disabled={requesting}
        onClick={() => toUpdate && modifyTag(toUpdate.id, true)}
      >
        Delete
      </Button>
    </div>
  );

  const ToggleTagVisibility = (tag: ITag) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          type="primary"
          disabled={requesting}
          onClick={() => toggleTagVisibility(tag)}
        >
          Confirm
        </Button>
      </div>
    );
  }

  const EditContent = (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Input
        value={value}
        allowClear
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={() =>
          toUpdate && !!value.length && modifyTag(toUpdate.id, false, value)
        }
      />
      <Button
        type="primary"
        disabled={requesting || !value.length}
        onClick={() => toUpdate && modifyTag(toUpdate.id, false, value)}
        style={{ marginLeft: '1rem' }}
      >
        Edit
      </Button>
    </div>
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Table
      dataSource={tags}
      rowKey="id"
      pagination={{
        position: ['bottomRight'],
        defaultPageSize: 10,
        total: tags ? tags.length : 0,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} of ${total} results`;
        },
        showSizeChanger: false,
      }}
      loading={{ spinning: requesting, indicator: antIcon }}
    >
      <Table.Column
        title="Title"
        dataIndex="tag"
        key="id"
        sorter={(a, b) => a.id - b.id}
        onCell={(record: ITag) => {
          return {
            className: 'clickable',
            onClick: () => {
              push({
                pathname: `/dashboard/tags/${tagType}/${record.tag
                  .toLowerCase()
                  .replace(/ /g, '')}/books`,
                state: { record },
              });
            },
          };
        }}
      />
      <Table.Column
        title="Actions"
        key="id"
        render={(tag: ITag) => {
          return (
            <div>
              <Popover
                content={EditContent}
                title="Edit this entry?"
                trigger="hover"
              >
                <EditTwoTone twoToneColor="#1890ff" />
              </Popover>
              <Popover
                content={DeleteContent}
                title="Delete this entry?"
                trigger="click"
              >
                <DeleteTwoTone
                  twoToneColor="#eb2f96"
                  style={{ marginLeft: '0.75rem' }}
                />
              </Popover>
              <Popover
                content={ToggleTagVisibility(tag)}
                title="Toggle tag visibility?"
                trigger="hover"
              >
                {tag.hidden ? (
                  <EyeInvisibleTwoTone
                    twoToneColor="#1890ff"
                    style={{ marginLeft: '0.75rem' }}
                  />
                ) : (
                  <EyeTwoTone
                    twoToneColor="#1890ff"
                    style={{ marginLeft: '0.75rem' }}
                  />
                )}
              </Popover>
            </div>
          )
        }}
        onCell={(record: ITag) => {
          return {
            onClick: () => {
              setToUpdate(record);
            },
          };
        }}
      />
    </Table>
  );
};

export default TagTable;
