import React from 'react';

import { StyledAuthLayout } from './auth.styled';
import Logo from '../../assets/images/readeo-logo.png';

export default function AuthLayout({ children }: any) {
  return (
    <StyledAuthLayout>
      <div className="logo">
        <img src={Logo} alt="readeo" />
      </div>

      {children}
    </StyledAuthLayout>
  );
}
