import React from 'react';
import { Statistic, Row, Col } from 'antd';
import { IGiftCard } from 'types';

export default function GiftCardStats({ data }: { data: IGiftCard }) {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Statistic title="Total" value={data.redeemed + data.unredeemed} />
      </Col>
      <Col span={6}>
        <Statistic title="Redeemed" value={data.redeemed} />
      </Col>
      <Col span={6}>
        <Statistic title="Not Redeemed" value={data.unredeemed} />
      </Col>
      <Col span={6}></Col>
    </Row>
  );
}
