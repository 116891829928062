import React from 'react';
import { Statistic, Row, Col, Popover } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { ISubSummary } from 'types';

export default function SubStats({ data }: { data: ISubSummary }) {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Popover content="Total active Subscriptions" placement="top">
          <Statistic title="Active" value={data.total_active} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Total expired Subscriptions" placement="top">
          <Statistic title="Expired" value={data.total_expired} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Total canceled Subscriptions" placement="top">
          <Statistic title="Canceled" value={data.total_canceled} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover
          content="Subscriptions canceled in the past 24hrs"
          placement="top"
        >
          <Statistic
            title="New Canceled"
            value={data.total_canceled_today}
            valueStyle={{ color: '#cf1322' }}
            prefix={<ArrowDownOutlined />}
          />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover
          content="Subscriptions created in the past 24hrs"
          placement="top"
        >
          <Statistic
            title="New"
            value={data.new_subs}
            valueStyle={{ color: '#3f8600' }}
            prefix={<ArrowUpOutlined />}
          />
        </Popover>
      </Col>
    </Row>
  );
}
