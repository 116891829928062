import React from 'react';
import { Select } from 'antd';

import { IBook } from 'types';

export interface IProps {
  books: IBook[] | { id: number; title: string }[];
  children: React.ReactNode;
  onChange: (values: number[]) => void;
}
const SelectBook = ({ books, children, onChange }: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Select
        mode="multiple"
        showSearch
        allowClear
        style={{ width: 500 }}
        placeholder="Select a book"
        optionFilterProp="children"
        onChange={onChange}
      >
        {books.map((book: any) => (
          <Select.Option key={book.id} value={book.id}>
            {book.title}
          </Select.Option>
        ))}
      </Select>
      {children}
    </div>
  );
};

export default SelectBook;
