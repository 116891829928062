import React from 'react';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { Space, Button, DatePicker, Typography } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import getLatest from 'utils/getLatest';

export interface IAddBOTWProps {
  book_id: number;
}

export interface IDataProps {
  book_id: number;
  tag_id: number;
  start_date: string;
  end_date: string;
}

const AddBOTW = ({ book_id }: IAddBOTWProps) => {
  const [latest, setLatest] = React.useState<string>('');
  const [reqBody, setReqBody] = React.useState<IDataProps>();
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [requesting, setRequesting] = React.useState<boolean>(false);

  const { push } = useHistory();

  React.useEffect(
    () => {
      getLatest({ setRequesting, setLatest });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const disabledDate = (current: Moment) => {
    // cannot select days on or before the most recent entry date
    return current && current < moment.utc(latest).subtract(5, 'h');
  };

  const onChange = (date: any, dateString: any) => {
    if (dateString[0] && dateString[1]) {
      // get difference in time between the two dates
      let diff =
        new Date(dateString[1]).getTime() - new Date(dateString[0]).getTime();
      // convert to days
      diff = diff / (1000 * 3600 * 24);
      // check if range starts from  Wednesday and ends 6 days after
      if (diff === 7 && moment(dateString[0]).isoWeekday() === 3) {
        setIsValid(true);
        setReqBody({
          book_id,
          tag_id: 101,
          start_date: dateString[0] + 'T05:00:00Z',
          end_date: dateString[1] + 'T04:59:59Z',
        });
      } else {
        setIsValid(false);
      }
    }
  };

  const clickHandler = async (e: any, data: IDataProps) => {
    setRequesting(true);
    try {
      await axiosInstance.post(`${config.baseUrl}/books/botw`, data);
      return push({ pathname: '/dashboard/book-of-the-week' });
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  return (
    <div>
      <Typography.Title level={5}>Set book of the week</Typography.Title>
      <Space direction="vertical">
        <DatePicker.RangePicker
          onChange={onChange}
          disabledDate={disabledDate}
        />
        <Button
          type="primary"
          style={{ width: '100%' }}
          disabled={!isValid || requesting}
          onClick={(e) => (reqBody ? clickHandler(e, reqBody) : null)}
        >
          Add
        </Button>
      </Space>
    </div>
  );
};

export default AddBOTW;
