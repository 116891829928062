import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';
import { UserOutlined, LockOutlined, NumberOutlined } from '@ant-design/icons';

import AuthLayout from '../../shared/AuthLayout';
import config from '../../../config';
import { useAuthContext } from '../../../hooks';
import axios from 'axios';

function Login() {
  const { user, setLoggedInUser } = useAuthContext();

  const [status, setStatus] = React.useState({
    isLoading: false,
    error: false,
    valid: false,
  });

  const [credentials, setCredentials] = React.useState({
    username: '',
    password: '',
    mfa: '',
  });

  const axiosInstance = axios.create({
    baseURL: config.baseUrl,
  });

  axiosInstance.defaults.withCredentials = true;

  const onFinish = async (values: any) => {
    setStatus({ isLoading: true, error: false, valid: false });
    try {
      const res = await axiosInstance.post(
        `${config.baseUrl}/auth/login`,
        values,
      );
      if (res && res.data.data[0]) {
        setStatus({ isLoading: false, error: false, valid: false });
        setLoggedInUser(res.data.data[0]);
      } else {
        return setStatus({ isLoading: false, error: false, valid: true });
      }
    } catch (error) {
      setStatus({ isLoading: false, error: true, valid: false });
      setCredentials((prev) => ({
        ...prev,
        password: '',
      }));
      message.error({
        content: 'Invalid email or password.',
      });
    }
  };

  const verifyMFA = async (values: any) => {
    setStatus({ isLoading: true, error: false, valid: true });
    try {
      const res = await axiosInstance.post(`${config.baseUrl}/twofa/verify`, {
        code: values.mfa,
      });
      if (res) {
        setStatus({ isLoading: false, error: false, valid: false });
        setLoggedInUser(res.data.data[0]);
      }
    } catch (error) {
      setStatus({ isLoading: false, error: true, valid: false });
      setCredentials((prev) => ({
        ...prev,
        password: '',
      }));
      message.error({
        content: 'Unable to log you in. Please try again later',
      });
    }
  };

  if (user) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <AuthLayout>
      <div className="form-card">
        <Typography>
          <Typography.Title level={3} style={{ color: '#083261' }}>
            Log in.
          </Typography.Title>
        </Typography>
        {status.valid && (
          <Form name="login" onFinish={verifyMFA}>
            <Form.Item
              name="mfa"
              className="form-input"
              rules={[
                {
                  required: true,
                  message: 'Please enter your MFA code to continue!',
                },
              ]}
              {...(status.error && {
                validateStatus: 'error',
              })}
            >
              <Input
                size="large"
                prefix={<NumberOutlined />}
                value={credentials.username}
                onChange={(e) =>
                  setCredentials((prev) => ({
                    ...prev,
                    mfa: e.target.value,
                  }))
                }
                autoFocus
                placeholder="Enter the MFA code from your app"
              />
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                size="large"
                loading={status.isLoading}
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        )}
        {!status.valid && (
          <Form name="login" onFinish={onFinish}>
            <Form.Item
              name="username"
              className="form-input"
              rules={[{ required: true, message: 'Please input your email!' }]}
              {...(status.error && {
                validateStatus: 'error',
              })}
            >
              <Input
                size="large"
                prefix={<UserOutlined />}
                value={credentials.username}
                onChange={(e) =>
                  setCredentials((prev) => ({
                    ...prev,
                    username: e.target.value,
                  }))
                }
                placeholder="email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
              {...(status.error && {
                validateStatus: 'error',
              })}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined />}
                type="password"
                value={credentials.password}
                onChange={(e) =>
                  setCredentials((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                placeholder="password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                size="large"
                loading={status.isLoading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </AuthLayout>
  );
}

export default Login;
