import React from 'react';
import { Button, Form, Input, message } from 'antd';
import ContentLayout from 'components/Layout';
import config from 'config';
import { IChangePassword } from 'types/ChangePassword';
import axios from 'axios';

const ChangePassword = () => {
  const axiosInstance = axios.create({
    baseURL: config.baseUrl,
  });

  axiosInstance.defaults.withCredentials = true;

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 12,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 12,
      },
      sm: {
        span: 8,
      },
    },
  };
  const [requesting, setRequesting] = React.useState<boolean>(false);

  const [form] = Form.useForm();

  const onFinish = async (data: IChangePassword) => {
    setRequesting(true);
    try {
      // create new coupon
      await axiosInstance
        .patch(`${config.baseUrl}/auth/changepassword`, data)
        .then(() => {
          form.resetFields();
          setRequesting(false);
          message.success({
            content: 'Password Updated',
          });
        })
        .catch(({ response }) => {
          setRequesting(false);
          if (response.status === 401) {
            message.error({
              content: 'Incorrect password entered',
            });
          } else {
            message.error({
              content: response.data.errors.newpassword,
            });
          }
        });
    } catch (error) {
      setRequesting(false);
      message.error({
        content: 'An error occurred updating your password',
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error(errorInfo);
  };

  return (
    <ContentLayout title="Change Password">
      <Form
        {...formItemLayout}
        form={form}
        name="changepassword"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="currentpassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: 'Please input your current password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newpassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm New Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your new password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newpassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!',
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={requesting}>
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </ContentLayout>
  );
};

export default ChangePassword;
