export enum Environments {
  qa = 'qa',
  development = 'development',
  production = 'production',
}

interface IEnvironmentVariables {
  baseUrl: string;
  assetsUrl: string;
  avatarUrl: string;
  appUrl: string;
}

export type Config = {
  [E in Environments]: IEnvironmentVariables;
};
