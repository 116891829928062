import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Users, Profile, CreateUser } from 'components/User';
import { Books, TagBooks, FreeBooks } from 'components/Book';
import { Tags, BOTW } from 'components/Tag';
import { Coupons } from 'components/Coupon';
import ChangePassword from 'components/Auth/ChangePassword';
import Stats from 'components/Stats';
import Bookshelves from 'components/Bookshelves';
export default function Content() {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}`} exact>
        <Users />
      </Route>

      <Route path={`${url}/user`} exact>
        <CreateUser />
      </Route>

      <Route path={`${url}/profile/:userId`} exact>
        <Profile />
      </Route>

      <Route path={`${url}/books`} exact>
        <Books />
      </Route>

      <Route path={`${url}/book-of-the-week`} exact>
        <BOTW />
      </Route>

      <Route path={`${url}/free-books`} exact>
        <FreeBooks />
      </Route>

      <Route path={`${url}/tags`} exact>
        <Tags />
      </Route>

      <Route path={`${url}/tags/:tag_type/:tag/books`} exact>
        <TagBooks />
      </Route>

      <Route path={`${url}/coupons`} exact>
        <Coupons />
      </Route>

      <Route path={`${url}/stats`} exact>
        <Stats />
      </Route>

      <Route path={`${url}/changepassword`} exact>
        <ChangePassword />
      </Route>
      <Route path={`${url}/bookshelves`} exact>
        <Bookshelves />
      </Route>
    </Switch>
  );
}
