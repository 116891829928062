import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { Space, Button, DatePicker, Typography, notification } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';

import config from 'config';
import axiosInstance from 'utils/axios';

export interface AddFreeBookProps {
  bookId: number;
}

const AddFreeBook = ({ bookId }: AddFreeBookProps) => {
  const [dates, setDates] = useState<any>([]);
  const [value, setValue] = useState<RangeValue<Moment>>();
  const [isLoading, setIsLoading] = useState(false);

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([]);
    }
  };

  const handleAddFreeBook = async () => {
    setIsLoading(true);
    try {
      const startDate = moment
        .utc(dates[0])
        .set({ h: 5, m: 0, s: 0 })
        .toISOString();
      const endDate = moment
        .utc(dates[1])
        .set({ h: 4, m: 59, s: 59 })
        .toISOString();
      await axiosInstance.post(`${config.baseUrl}/books/free/${bookId}`, {
        startDate: startDate,
        endDate: endDate,
      });
      notification['success']({
        message: 'Book added to free books successfully',
        duration: 6,
      });
      setDates([]);
      setValue(undefined);
    } catch (error) {
      notification['error']({
        message: 'Error adding free book',
        description: error.response.data.message || error.message,
        duration: 6,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Typography.Title level={5}>Set free book</Typography.Title>
      <Space direction="vertical">
        <DatePicker.RangePicker
          value={value}
          onOpenChange={onOpenChange}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setValue(val)}
          disabledDate={(current) =>
            current && current < moment().subtract(1, 'days').endOf('day')
          }
        />
        <Button
          style={{ width: '100%' }}
          type="primary"
          onClick={handleAddFreeBook}
          disabled={!(dates?.length === 2 && dates[0] && dates[1])}
          loading={isLoading}
        >
          Add
        </Button>
      </Space>
    </div>
  );
};

export default AddFreeBook;
