import React from 'react';
import { Input } from 'antd';
const { Search } = Input;

interface ISearchbar {
  val: string;
  setVal: React.Dispatch<React.SetStateAction<string>>;
  searchHandler: () => void;
}

function Searchbar({ val, setVal, searchHandler }: ISearchbar) {
  const onChange = (e: any) => {
    setVal(e.target.value);
  };

  return (
    <Search
      placeholder="Search"
      onChange={onChange}
      onSearch={searchHandler}
      value={val}
      style={{ margin: '0 0 20px 0' }}
      enterButton
      allowClear
    />
  );
}

export default Searchbar;
