import React from 'react';
import moment from 'moment';
import { Table, Space, Tag } from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import config from 'config';

import { useUserContext } from 'hooks/useUserContext';

const UserProfile = () => {
  const [userData, setUserData] = React.useState<any[]>();

  const { selectedUser } = useUserContext();

  React.useEffect(() => {
    if (selectedUser) {
      setUserData([selectedUser]);
    }
  }, [selectedUser]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Table
      dataSource={userData}
      rowKey="id"
      pagination={{
        position: ['bottomRight'],
        defaultPageSize: 10,
        total: 1,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} of ${total} results`;
        },
        showSizeChanger: false,
      }}
      loading={{ spinning: !userData?.length, indicator: antIcon }}
    >
      <Table.Column title="First Name" dataIndex="first_name" key="id" />
      <Table.Column title="Last Name" dataIndex="last_name" key="id" />
      <Table.Column title="Email Address" dataIndex="username" key="id" />
      <Table.Column
        title="Subscription"
        dataIndex="account_type"
        render={(text: any, row: any) => {
          if (text === 0) {
            return <Tag color="default">GUEST</Tag>;
          } else if (text === 8) {
            return row?.roles?.some((role: any) => {
              return role.role === 'SCHOOL_TEACHER';
            }) ? (
              <Tag color="blue">TEACHER</Tag>
            ) : (
              <Tag color="blue">VIP</Tag>
            );
          } else if (text === 10) {
            <Tag color="purple">CLUB</Tag>;
          } else {
            return (
              <Tag icon={<CheckCircleOutlined />} color="green">
                ACTIVE
              </Tag>
            );
          }
        }}
      />
      <Table.Column
        title="Family Plan"
        render={(item) =>
          item.fpstatus ? (
            <Tag icon={<CheckCircleOutlined />} color="green">
              YES
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="red">
              NO
            </Tag>
          )
        }
      />
      <Table.Column
        title="Joined"
        render={(item) => (
          <Space size="large" align="baseline">
            {moment(item.create_date).format('MMMM Do YYYY h:MM A')}
          </Space>
        )}
      />
      <Table.Column
        title="Bookchat Room"
        dataIndex="activationKey"
        render={(activationKey) => (
          <a
            href={`${config.appUrl}/room/${activationKey}`}
            target="_blank"
            rel="noreferrer"
          >{`${config.appUrl}/room/${activationKey}`}</a>
        )}
        ellipsis={true}
      />
      <Table.Column
        title="Buddy Invite Link"
        dataIndex="buddy_invite_code"
        render={(buddy_invite_code) => (
          <a
            href={`${config.appUrl}/signup?code=${buddy_invite_code}`}
            target="_blank"
            rel="noreferrer"
          >{`${config.appUrl}?code=${buddy_invite_code}`}</a>
        )}
        ellipsis={true}
      />
    </Table>
  );
};

export default UserProfile;
