import React, { useState, useEffect } from 'react';
import { Popover, List, Button, Typography, Table, Space } from 'antd';
import moment from 'moment-timezone';

import ContentLayout from 'components/Layout';
import { IBook } from 'types';
import axiosInstance from 'utils/axios';
import config from 'config';

interface IFreeBook {
  book: IBook;
  book_id: IBook['id'];
  create_date: Date;
  end_date: Date;
  id: number;
  start_date: Date;
  tag_id: number;
}

const FreeBooks = () => {
  const [state, setState] = useState<{
    isLoading: boolean;
    freeBooks: IFreeBook[];
  }>({ isLoading: true, freeBooks: [] });
  const [isRemoving, setRemoving] = useState(false);

  const handleRemove = async (bookId: number, bookTagId: number) => {
    setRemoving(true);
    try {
      await axiosInstance.delete(
        `${config.baseUrl}/books/free/${bookId}/${bookTagId}`,
      );
      setState((prev) => ({
        ...prev,
        freeBooks: prev.freeBooks.filter((book) => book.id !== bookTagId),
      }));
    } catch (error) {
      console.error('error: ', error);
    } finally {
      setRemoving(false);
    }
  };

  useEffect(() => {
    const getFreeBooks = async () => {
      setState((prev) => ({ ...prev, isLoading: true }));
      try {
        const { data: res } = await axiosInstance.get(
          `${config.baseUrl}/books/free`,
        );

        setState((prev) => ({ ...prev, freeBooks: res.data }));
      } catch (error) {
        console.error('error: ', error);
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }));
      }
    };

    getFreeBooks();
  }, []);

  const today = moment().valueOf();
  const currentlyShowing = state.freeBooks.filter(
    (book) =>
      moment(book.start_date).startOf('day').valueOf() <= today &&
      today <= moment(book.end_date).endOf('day').valueOf(),
  );

  return (
    <ContentLayout title="Free Books">
      <Space size={10} direction="vertical" style={{ width: '100%' }}>
        <List
          bordered
          size="small"
          header={
            <Typography.Title level={5}>
              Currently showing ({currentlyShowing.length}):
            </Typography.Title>
          }
          dataSource={currentlyShowing}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.book.title}
                description={
                  <p style={{ marginBottom: 0, fontSize: '12px' }}>
                    Start date:{' '}
                    {moment(item.start_date)
                      .tz('America/New_York')
                      .format('ddd, MMM Do YYYY HH:mm ZZ z')}{' '}
                    | End date:{' '}
                    {moment(item.end_date)
                      .tz('America/New_York')
                      .format('ddd, MMM Do YYYY HH:mm ZZ z')}
                  </p>
                }
              />
            </List.Item>
          )}
        />

        <Table
          dataSource={state.freeBooks.map((book) => ({
            ...book,
            key: book.id,
          }))}
        >
          <Table.Column
            title="Book id"
            dataIndex="book_id"
            key="book_id"
            sorter={(a: IFreeBook, b: IFreeBook) => a.id - b.id}
          />
          <Table.Column
            title="Title"
            key="title"
            sorter={(a: IFreeBook, b: IFreeBook) =>
              a.book.title < b.book.title ? -1 : 1
            }
            render={(record) => record.book.title}
          />
          <Table.Column
            title="Start date"
            key="start_date"
            sorter={(a: IFreeBook, b: IFreeBook) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime()
            }
            render={(record) =>
              moment(record.start_date)
                .tz('America/New_York')
                .format('ddd, MMM Do YYYY HH:mm ZZ z')
            }
          />
          <Table.Column
            title="End date"
            key="end_date"
            sorter={(a: IFreeBook, b: IFreeBook) =>
              new Date(a.end_date).getTime() - new Date(b.end_date).getTime()
            }
            render={(record) =>
              moment(record.end_date)
                .tz('America/New_York')
                .format('ddd, MMM Do YYYY HH:mm ZZ z')
            }
          />
          <Table.Column
            title="Action"
            key="action"
            render={({ book, id }) => (
              <Space size="middle">
                <Popover
                  trigger="click"
                  content={() => (
                    <Button
                      danger
                      type="primary"
                      onClick={() => handleRemove(book.id, id)}
                      disabled={isRemoving}
                      loading={isRemoving}
                    >
                      Confirm remove
                    </Button>
                  )}
                  title="Remove book"
                >
                  <Button danger size="small" disabled={isRemoving}>
                    Remove
                  </Button>
                </Popover>
              </Space>
            )}
          />
        </Table>
      </Space>
    </ContentLayout>
  );
};

export default FreeBooks;
