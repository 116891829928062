import React from 'react';
import axiosInstance from 'utils/axios';
import { Button, Input, Popover, Table, Tag } from 'antd';
import moment from 'moment';
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';

import config from 'config';

export interface IUserSubscriptionsProps {
  userId: string;
}

const UserSubscriptions = ({ userId }: IUserSubscriptionsProps) => {
  const [subs, setSubs] = React.useState<any[]>();
  const [value, setValue] = React.useState<string>('');
  const [flagged, setFlagged] = React.useState<string>('');
  const [requesting, setRequesting] = React.useState<boolean>(false);

  React.useEffect(() => {
    getAllSubscriptions(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllSubscriptions = (user_id: string) => {
    setRequesting(true);
    axiosInstance
      .get(`${config.baseUrl}/subscriptions/${user_id}`)
      .then(({ data }) => {
        setRequesting(false);
        const modData = data.data.map((el: any) => ({
          ...el,
          create_date: moment(el.create_date).format('MMMM Do YYYY h:MM A'),
          first_bill_date: moment(el.first_bill_date).format(
            'MMMM Do YYYY h:MM A',
          ),
        }));
        setSubs(modData);
      })
      .catch((err) => {
        setRequesting(false);
        console.error(err);
      });
  };

  const cancelActiveSubscription = (account_code: string) => {
    setRequesting(true);
    axiosInstance
      .delete(`${config.baseUrl}/subscriptions/${account_code}`)
      .then(() => {
        setRequesting(false);
        getAllSubscriptions(userId);
      })
      .catch((err) => {
        setRequesting(false);
        console.error(err);
      });
  };

  const ReactivateSubscription = (account_code: string) => {
    setRequesting(true);
    axiosInstance
      .patch(`${config.baseUrl}/subscriptions/${account_code}`)
      .then(() => {
        setRequesting(false);
        getAllSubscriptions(userId);
      })
      .catch((err) => {
        setRequesting(false);
        console.error(err);
      });
  };

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const Cancel = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input
        placeholder={"Type 'delete' to confirm "}
        value={value}
        onChange={onChange}
        style={{ marginBottom: '1rem' }}
      />
      {
        <Button
          type="primary"
          disabled={value !== 'delete' || requesting}
          onClick={() => flagged && cancelActiveSubscription(flagged)}
        >
          Proceed
        </Button>
      }
    </div>
  );

  const Reactivate = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input
        placeholder={"Type 'reactivate' to confirm "}
        value={value}
        onChange={onChange}
        style={{ marginBottom: '1rem' }}
      />
      {
        <Button
          type="primary"
          disabled={value !== 'reactivate' || requesting}
          onClick={() => flagged && ReactivateSubscription(flagged)}
        >
          Proceed
        </Button>
      }
    </div>
  );

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Table
      dataSource={subs}
      rowKey="id"
      pagination={{
        position: ['bottomRight'],
        defaultPageSize: 10,
        total: 1,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} of ${total} results`;
        },
        showSizeChanger: false,
      }}
      loading={{ spinning: requesting, indicator: antIcon }}
    >
      <Table.Column title="Subscription Id" dataIndex="id" key="id" />
      <Table.Column title="Created on" dataIndex="create_date" key="id" />
      <Table.Column
        title="First billed on"
        dataIndex="first_bill_date"
        key="id"
      />
      <Table.Column
        title="Status"
        dataIndex="status"
        render={(text) =>
          text === 'active' ? (
            <Tag icon={<CheckCircleOutlined />} color="green">
              ACTIVE
            </Tag>
          ) : text === 'expired' ? (
            <Tag>EXPIRED</Tag>
          ) : (
            <Tag>CANCELED</Tag>
          )
        }
      />
      <Table.Column
        title="Action"
        render={(item) =>
          item.status === 'active' ? (
            <Popover
              content={Cancel}
              title="Cancel this subscription"
              trigger="click"
            >
              <Button danger onClick={() => setFlagged(item.id)}>
                Cancel
              </Button>
            </Popover>
          ) : item.status === 'canceled' ? (
            <Popover
              content={Reactivate}
              title="Reactivate this subscription"
              trigger="click"
            >
              <Button type="primary" onClick={() => setFlagged(item.id)}>
                Reactivate
              </Button>
            </Popover>
          ) : null
        }
      ></Table.Column>
    </Table>
  );
};

export default UserSubscriptions;
