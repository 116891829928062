import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';

import { AppRoute } from '../../routes';
import AuthContextProvider from '../../contexts/AuthContext';

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <Layout className="main">
          <AppRoute />
        </Layout>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
