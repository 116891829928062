import React from 'react';
import { Button, Select } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import { IBookTag } from 'types';
import { ITag } from 'types';

const RemoveBookTag = ({ book, onFinish }: IBookTag) => {
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [tagId, setTagId] = React.useState<number>();

  const onChange = (value: any) => {
    setTagId(value);
  };

  const clickHandler = async () => {
    setRequesting(true);
    try {
      if (book) {
        await axiosInstance.delete(
          `${config.baseUrl}/books/${book.id}/tag/${tagId}`,
        );
        onFinish();
        return setRequesting(false);
      }
      return setRequesting(false);
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a category"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {book?.tags
            .filter((tag) => tag.tag_type === 'category')
            .map((category: ITag) => (
              <Select.Option value={category.id}>{category.tag}</Select.Option>
            ))}
        </Select>
        <Button
          type="primary"
          disabled={!tagId || requesting}
          style={{ marginTop: '1rem' }}
          onClick={clickHandler}
        >
          Remove Category
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1.5rem',
        }}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select an age range"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {book?.tags
            .filter((tag) => tag.tag_type === 'age')
            .map((category: ITag) => (
              <Select.Option value={category.id}>{category.tag}</Select.Option>
            ))}
        </Select>
        <Button
          type="primary"
          disabled={!tagId || requesting}
          style={{ marginTop: '1rem' }}
          onClick={clickHandler}
        >
          Remove Age
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1.5rem',
        }}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a featured tag"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {book?.tags
            .filter((tag) => tag.tag_type === 'curated')
            .map((category: ITag) => (
              <Select.Option value={category.id}>{category.tag}</Select.Option>
            ))}
        </Select>
        <Button
          type="primary"
          disabled={!tagId || requesting}
          style={{ marginTop: '1rem' }}
          onClick={clickHandler}
        >
          Remove Featured
        </Button>
      </div>
    </>
  );
};

export default RemoveBookTag;
