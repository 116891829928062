import config from 'config';
import axiosInstance from 'utils/axios';
import { IBook } from 'types';

export interface IGetBooksProps {
  addTags?: boolean;
  setRequesting: (value: React.SetStateAction<boolean>) => void;
  setBooks: (value: React.SetStateAction<any>) => void;
}

const renderTag = (item: IBook, tagType: string) => {
  if (item) {
    return item.tags
      .filter((tag: any) => tag.tag_type === tagType)
      .reduce(
        (acc: string, cur: any) => acc + `${acc.length ? ',' : ''} ${cur.tag}`,
        '',
      );
  }
};

const getBooks = async ({
  addTags = true,
  setRequesting,
  setBooks,
}: IGetBooksProps) => {
  setRequesting(true);
  try {
    let allBooks = await axiosInstance.get(`${config.baseUrl}/books`);
    if (addTags) {
      // include tags, then set books
      setBooks(
        allBooks.data.data.map((book: IBook) => ({
          ...book,
          categories: renderTag(book, 'category'),
          age: renderTag(book, 'age'),
          curated: renderTag(book, 'curated'),
        })),
      );
    } else {
      setBooks(allBooks.data.data);
    }
    setRequesting(false);
  } catch (error) {
    setRequesting(false);
    console.error('error: ', error);
  }
};

export default getBooks;
