import React from 'react';
import { Layout } from 'antd';

import Header from '../Header';
import Sidebar from '../Sidebar';
import { ContentRoute } from 'routes';
import AppContextProvider from 'contexts/AppContext';
import UserContextProvider from 'contexts/UserContext';
import BookContextProvider from 'contexts/BookContext';

function Dashboard() {
  return (
    <AppContextProvider>
      <Header />
      <Layout>
        <Sidebar />
        <UserContextProvider>
          <BookContextProvider>
            <ContentRoute />
          </BookContextProvider>
        </UserContextProvider>
      </Layout>
    </AppContextProvider>
  );
}

export default Dashboard;
