import React, { useState, useEffect } from 'react';
import { Button, Popover, Table, Tag } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import {
  LoadingOutlined,
  MinusCircleTwoTone,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import config from 'config';
import axiosInstance from 'utils/axios';
import getTableFilter from 'utils/getTableFilter';

import { IBook } from 'types';
import Searchbar from 'components/shared/Searchbar';
import ContentLayout from 'components/Layout';

const tagTypes: any = {
  category: 'Category',
  age: 'Age',
  curated: 'Featured',
  botw: 'Book of the Week',
};

const TagBooks = () => {
  const [val, setVal] = useState('');
  const [books, setBooks] = useState([]);
  const [toUpdate, setToUpdate] = useState<IBook | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);

  const location = useLocation();
  const { tag_type } = useParams<any>();

  useEffect(() => {
    if (!val && tag_type) {
      getTagBooks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, tag_type]);

  const getTagBooks = async () => {
    try {
      setRequesting(true);
      let allTagBooks = await axiosInstance.get(
        // @ts-ignore
        `${config.baseUrl}/tags/${tag_type}/${location.state.record.id}/books`,
      );
      return setBooks(
        allTagBooks.data.data.map((book: IBook) => ({
          ...book,
          [tag_type]: renderTag(book, tag_type),
        })),
      );
    } catch (error) {
      console.error('error: ', error);
    } finally {
      setRequesting(false);
    }
  };

  const searchBooks = () => {
    setBooks((books) =>
      books.filter(
        (book: IBook) =>
          book.title.toLowerCase().includes(val) ||
          book.author.toLowerCase().includes(val),
      ),
    );
  };

  const renderTag = (item: IBook, tagType: string) => {
    return item.tags
      .filter((tag: any) => tag.tag_type === tagType)
      .reduce(
        (acc: string, cur: any) => acc + `${acc.length ? ',' : ''} ${cur.tag}`,
        '',
      );
  };

  const modifyTag = async (id: number) => {
    setRequesting(true);
    try {
      if (toUpdate) {
        // remove tag from book
        await axiosInstance.delete(
          `${config.baseUrl}/books/${toUpdate.id}/tag/${id}`,
        );
      }
      // fetch all tag books
      return getTagBooks();
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  const RemoveTagFromBook = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        type="primary"
        disabled={requesting}
        // @ts-ignore
        onClick={() => modifyTag(location.state.record.id)}
      >
        Remove Tag
      </Button>
    </div>
  );

  let authors: any;
  let publishers: any;

  if (books.length > 0) {
    authors = getTableFilter(books, 'author');
    publishers = getTableFilter(books, 'publisher', true);
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    // @ts-ignore
    <ContentLayout title={`Tag: ${location.state.record.tag}`}>
      <Searchbar val={val} setVal={setVal} searchHandler={searchBooks} />
      <Table
        bordered
        dataSource={books}
        rowKey="id"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 10,
          total: books ? books.length : 0,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          showSizeChanger: false,
        }}
        loading={{ spinning: requesting, indicator: antIcon }}
      >
        <Table.Column
          title="Title"
          dataIndex="title"
          sorter={(a: IBook, b: IBook) => a.id - b.id}
        />
        <Table.Column
          title="Author"
          dataIndex="author"
          sorter={(a: IBook, b: IBook) =>
            a.author > b.author ? -1 : b.author > a.author ? 1 : 0
          }
          filters={authors}
          onFilter={(value, record) =>
            typeof value === 'string' && record.author.includes(value)
          }
        />
        <Table.Column
          title="Publisher"
          sorter={(a: IBook, b: IBook) =>
            a.publisher.name > b.publisher.name
              ? -1
              : b.publisher.name > a.publisher.name
              ? 1
              : 0
          }
          filters={publishers}
          onFilter={(value, record) =>
            typeof value === 'string' && record.publisher.name.includes(value)
          }
          render={(book) => <div>{book.publisher.name}</div>}
        />
        <Table.Column
          title={tagTypes[tag_type]}
          render={(book) => <div>{book[tag_type]}</div>}
        />
        <Table.Column
          title="Status"
          render={(book) =>
            book.active ? (
              <Tag icon={<CheckCircleOutlined />} color="blue">
                ACTIVE
              </Tag>
            ) : (
              <Tag icon={<CloseCircleOutlined />} color="default">
                INACTIVE
              </Tag>
            )
          }
        />
        <Table.Column
          title="Action"
          key="id"
          render={() => (
            <div>
              <Popover
                content={RemoveTagFromBook}
                title="Remove tag from this Book?"
                trigger="click"
              >
                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ marginLeft: '0.75rem' }}
                />
              </Popover>
            </div>
          )}
          onCell={(record: IBook) => {
            return {
              onClick: () => {
                setToUpdate(record);
              },
            };
          }}
        />
      </Table>
    </ContentLayout>
  );
};

export default TagBooks;
