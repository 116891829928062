import React from 'react';
import { Table, Tag, Popover, Button } from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import config from 'config';
import axiosInstance from 'utils/axios';

const PopoverContent = (userId?: number) => {
  const history = useHistory();
  const showPendingUser = async (_userId?: number) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `${config.baseUrl}/users/${_userId}/pending-buddy`,
      );
      if (data && data.length)
        history.push(`/dashboard/profile/${data[0].buddy_id}`);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  return <Button  type="primary" onClick={() => showPendingUser(userId)}>View</Button>;
};
const DisplayStatus = ({
  status,
  userId,
}: {
  status: number;
  userId?: number;
}) => {
  if (status === 1)
    return (
      <Popover content={() => PopoverContent(userId)} trigger="hover">
        <Tag icon={<ExclamationCircleOutlined />} color="yellow">
          PENDING
        </Tag>
      </Popover>
    );
  if (status === 2)
    return (
      <Tag icon={<CheckCircleOutlined />} color="blue">
        ACCEPTED
      </Tag>
    );
  if (status === 3)
    return (
      <Tag icon={<CheckCircleOutlined />} color="green">
        COMPLETED
      </Tag>
    );
  return (
    <Tag icon={<CloseCircleOutlined />} color="red">
      NOT STARTED
    </Tag>
  );
};

const UserProgress = ({ progress, userId }: any) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Table
      dataSource={progress}
      rowKey="total"
      pagination={{
        position: ['bottomRight'],
        defaultPageSize: 10,
        total: 1,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} of ${total} results`;
        },
        showSizeChanger: false,
      }}
      loading={{ spinning: !progress?.length, indicator: antIcon }}
    >
      <Table.Column
        title="Add Buddy"
        dataIndex="steps"
        key="add_buddy"
        render={(text: any) => {
          const status = text.add_buddy.status;
          return <DisplayStatus status={status} userId={userId} />;
        }}
      />
      <Table.Column
        title="First Bookchat"
        dataIndex="steps"
        key="first_bookchat"
        render={(text: any) => {
          const status = text.first_bookchat.status;
          return <DisplayStatus status={status} />;
        }}
      />
      <Table.Column
        title="System Check"
        dataIndex="steps"
        key="system_check"
        render={(text: any) => {
          const status = text.system_check.status;
          return <DisplayStatus status={status} />;
        }}
      />
      <Table.Column
        title="System Tour"
        dataIndex="steps"
        key="system_tour"
        render={(text: any) => {
          const status = text.system_tour.status;
          return <DisplayStatus status={status} />;
        }}
      />
      <Table.Column
        title="Update Avatar"
        dataIndex="steps"
        key="update_avatar"
        render={(text: any) => {
          const status = text.update_avatar.status;
          return <DisplayStatus status={status} />;
        }}
      />
      <Table.Column
        title="Watch Tutorial"
        dataIndex="steps"
        key="watch_tutorial"
        render={(text: any) => {
          const status = text.watch_tutorial.status;
          return <DisplayStatus status={status} />;
        }}
      />
      <Table.Column
        title="Percentage"
        dataIndex="total"
        key="total"
        render={(text: any) => {
          return `${text}%`;
        }}
      />
    </Table>
  );
};

export default UserProgress;
