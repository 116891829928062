import React from 'react';
import { Statistic, Row, Col, Popover } from 'antd';
import { IFreeUserMetrics } from 'types';

export default function FreeUserStats({ data }: { data: IFreeUserMetrics }) {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Popover
          content="Total number of free users"
          placement="top"
        >
          <Statistic title="Total" value={data.total_free_users} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover
          content="Number of free users who have buddies"
          placement="top"
        >
          <Statistic title="Has buddies" value={data.users_with_buddies} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Free users that have bookchatted" placement="top">
          <Statistic title="Who bookchatted" value={data.total_bookchats} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Free users that have bookread" placement="top">
          <Statistic title="Who bookread" value={data.total_bookreads} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Free users who upgraded" placement="left">
          <Statistic title="Who upgraded" value={data.total_upgrades} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover
          content="Free users who upgraded with ReveneCat"
          placement="bottom"
        >
          <Statistic
            title="Upgrade with ReveneCat"
            value={data.revenuecat_subs}
          />
        </Popover>
      </Col>
    </Row>
  );
}
