import config from 'config';
import axiosInstance from 'utils/axios';
import { ITag } from 'types';

export interface IGetTagsProps {
  tagType: string;
  setTags: (value: React.SetStateAction<ITag[]>) => void;
  setRequesting?: (value: React.SetStateAction<boolean>) => void;
}

const alphabetically = (a: ITag, b: ITag) => {
  // ignore case
  const tagA = a.tag.toUpperCase();
  const tagB = b.tag.toUpperCase();

  if (tagA < tagB) return -1;
  if (tagA > tagB) return 1;
  return 0;
};

const getTags = async ({ tagType, setTags, setRequesting }: IGetTagsProps) => {
  setRequesting && setRequesting(true);
  try {
    const allTags = await axiosInstance.get(
      `${config.baseUrl}/tags/${tagType}`,
    );

    setTags(allTags.data.data.sort(alphabetically));
    setRequesting && setRequesting(false);
  } catch (error) {
    setRequesting && setRequesting(false);
    console.error('error: ', error);
  }
};

export default getTags;
