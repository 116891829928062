import React from 'react';
import { Table, Tag } from 'antd';
import {
  LoadingOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

const { Column } = Table;

interface IUserBuddies {
  buddies: any;
}

const UserBuddies = ({ buddies }: IUserBuddies) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Table
        dataSource={buddies}
        rowKey="id"
        loading={{ spinning: !buddies, indicator: antIcon }}
        pagination={false}
      >
        <Column
          title="Full Name"
          render={(item) => (
            <div>
              {item.first_name} {item.last_name}
            </div>
          )}
        />
        <Column
          title="Subscription"
          dataIndex="account_type"
          render={(text: any, row: any) => {
            if (text === 0) {
              return <Tag color="default">GUEST</Tag>;
            } else if (text === 8) {
              return row?.roles?.some((role: any) => {
                return role.role === 'SCHOOL_TEACHER';
              }) ? (
                <Tag color="blue">TEACHER</Tag>
              ) : (
                <Tag color="blue">VIP</Tag>
              );
            } else if (text === 10) {
              <Tag color="purple">CLUB</Tag>;
            } else {
              return (
                <Tag icon={<CheckCircleOutlined />} color="green">
                  ACTIVE
                </Tag>
              );
            }
          }}
        />
        <Column
          title="Family Plan"
          render={(item) =>
            item.fpstatus ? (
              <Tag icon={<CheckCircleOutlined />} color="green">
                YES
              </Tag>
            ) : (
              <Tag icon={<CloseCircleOutlined />} color="red">
                NO
              </Tag>
            )
          }
        />
      </Table>
    </>
  );
};

export default UserBuddies;
