import React from 'react';
import { Button, Select, Space, Typography } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import getTags from 'utils/getTags';
import { IBookTag } from 'types';
import { ITag } from 'types';

const AddBookTag = ({ book, onFinish }: IBookTag) => {
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<ITag[]>([]);
  const [age, setAge] = React.useState<ITag[]>([]);
  const [featuredTags, setFeaturedTags] = React.useState<ITag[]>([]);

  const [tagId, setTagId] = React.useState<number>();

  React.useEffect(
    () => {
      getTags({ tagType: 'category', setTags: setCategories, setRequesting });
      getTags({ tagType: 'age', setTags: setAge, setRequesting });
      getTags({ tagType: 'curated', setTags: setFeaturedTags, setRequesting });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onChange = (value: any) => {
    setTagId(value);
  };

  const clickHandler = async () => {
    setRequesting(true);
    try {
      if (book) {
        await axiosInstance.post(`${config.baseUrl}/books/tag`, {
          book_id: book.id,
          tag_id: tagId,
        });
        onFinish();
        return setRequesting(false);
      }
      return setRequesting(false);
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  return (
    <div>
      <Typography.Title level={5}>Set tags</Typography.Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a category"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categories
              // filter existing category tags on a book
              .filter((category) => !book?.categories.includes(category.tag))
              .map((category: ITag) => (
                <Select.Option value={category.id} key={category.id}>
                  {category.tag}
                </Select.Option>
              ))}
          </Select>
          <Button
            type="primary"
            style={{ width: '100%' }}
            disabled={!tagId || requesting}
            onClick={clickHandler}
          >
            Add category
          </Button>
        </Space>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select an age range"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {age
              // filter existing age tags on a book
              .filter((age) => !book?.age.includes(age.tag))
              .map((age: ITag) => (
                <Select.Option value={age.id} key={age.id}>
                  {age.tag}
                </Select.Option>
              ))}
          </Select>
          <Button
            type="primary"
            style={{ width: '100%' }}
            disabled={!tagId || requesting}
            onClick={clickHandler}
          >
            Add age
          </Button>
        </Space>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a featured tag"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {featuredTags
              // filter existing featured tags on a book
              .filter((featured) => !book?.curated.includes(featured.tag))
              .map((featured: ITag) => (
                <Select.Option value={featured.id} key={featured.id}>
                  {featured.tag}
                </Select.Option>
              ))}
          </Select>
          <Button
            type="primary"
            style={{ width: '100%' }}
            disabled={!tagId || requesting}
            onClick={clickHandler}
          >
            Add featured
          </Button>
        </Space>
      </Space>
    </div>
  );
};

export default AddBookTag;
