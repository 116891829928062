import React from 'react';
import { Statistic, Row, Col, Popover } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { IUserSummary } from 'types';

export default function UsersStats({ data }: { data: IUserSummary }) {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Statistic title="Total" value={data.guests + data.subscribed} />
      </Col>
      <Col span={6}>
        <Popover content="Users who logged in the past 24hrs" placement="top">
          <Statistic title="Active Today" value={data.active_today} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Users with active subscriptions" placement="top">
          <Statistic title="Subscribed" value={data.subscribed} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Users without active subscriptions" placement="top">
          <Statistic title="Guests" value={data.guests} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Number of VIP users" placement="left">
          <Statistic title=" VIP" value={data.vips} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Signups in the past 24hrs" placement="right">
          <Statistic
            title="New Signups"
            value={data.new}
            valueStyle={{ color: '#3f8600' }}
            prefix={<ArrowUpOutlined />}
          />
        </Popover>
      </Col>
    </Row>
  );
}
