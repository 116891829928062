import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  UserAddOutlined,
  BookOutlined,
  TagOutlined,
  GiftOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

function Sidebar() {
  const { url } = useRouteMatch();

  return (
    <Sider width={220}>
      <Menu
        mode="inline"
        style={{ height: '100%', paddingTop: '50px', borderRight: 0 }}
      >
        <Menu.Item key="1">
          <UserOutlined />
          <NavLink to={url} activeClassName="selected">
            All Users
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <UserAddOutlined />
          <NavLink to={`${url}/user`} activeClassName="selected">
            Create VIP User
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <BookOutlined />
          <NavLink to={`${url}/books`} activeClassName="selected">
            All Books
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <BookOutlined />
          <NavLink to={`${url}/book-of-the-week`} activeClassName="selected">
            Book Of The Week
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <BookOutlined />
          <NavLink to={`${url}/free-books`} activeClassName="selected">
            Free books
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <BookOutlined />
          <NavLink to={`${url}/bookshelves`} activeClassName="selected">
            Bookshelves
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <TagOutlined />
          <NavLink to={`${url}/tags`} activeClassName="selected">
            All Tags
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <GiftOutlined />
          <NavLink to={`${url}/coupons`} activeClassName="selected">
            All Coupons
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <UserOutlined />
          <NavLink to={`${url}/changepassword`} activeClassName="selected">
            My Profile
          </NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default Sidebar;
