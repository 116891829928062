import React from 'react';
import { Button, Select } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import { IBook } from 'types';

export interface IEditBOTWProps {
  id?: number;
  books: IBook[];
  onFinish: () => void;
}
const EditBOTW = ({ id, books, onFinish }: IEditBOTWProps) => {
  const [requesting, setRequesting] = React.useState(false);
  const [bookId, setBookId] = React.useState();

  const onChange = (value: any) => {
    setBookId(value);
  };

  const clickHandler = async () => {
    setRequesting(true);
    try {
      await axiosInstance.put(`${config.baseUrl}/books/botw/${id}`, { bookId });
      return onFinish();
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  return books.length ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a book"
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) =>
          option?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Select.Option key={-1} value={-1}>
          None
        </Select.Option>
        {books.map((book: any) => (
          <Select.Option key={book.id} value={book.id}>
            {book.title}
          </Select.Option>
        ))}
      </Select>
      <Button
        type="primary"
        disabled={requesting}
        style={{ marginTop: '1rem' }}
        onClick={clickHandler}
      >
        Update
      </Button>
    </div>
  ) : null;
};

export default EditBOTW;
