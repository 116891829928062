import React from 'react';
import { Popover, Table, Tag, Space } from 'antd';
import {
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { IBook, ITag } from 'types';
import getBooks from 'utils/getBooks';
import color from 'utils/getTagColor';
import getTags from 'utils/getTags';
import getTableFilter from 'utils/getTableFilter';

import AddBOTW from './AddBOTW';
import AddBookTag from './AddBookTag';
import RemoveBookTag from './RemoveBookTag';
import ContentLayout from 'components/Layout';
import Searchbar from 'components/shared/Searchbar';

import AddFreeBook from './AddFreeBook';

const Books = () => {
  const [val, setVal] = React.useState('');
  const [books, setBooks] = React.useState<IBook[]>([]);
  const [categories, setCategories] = React.useState<ITag[]>([]);
  const [ages, setAge] = React.useState<ITag[]>([]);
  const [featuredTags, setFeaturedTags] = React.useState<ITag[]>([]);

  const [bookToUpdate, setBookToUpdate] = React.useState<IBook>();
  const [requesting, setRequesting] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      getTags({ tagType: 'category', setTags: setCategories });
      getTags({ tagType: 'age', setTags: setAge });
      getTags({ tagType: 'curated', setTags: setFeaturedTags });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (!val) {
      getBooks({ setRequesting, setBooks });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  const searchBooks = () => {
    setBooks((books) =>
      books.filter(
        (book: IBook) =>
          book.title.toLowerCase().includes(val.toLowerCase()) ||
          book.author.toLowerCase().includes(val.toLowerCase()),
      ),
    );
  };

  let authors: any;
  let publishers: any;

  if (books.length > 0) {
    authors = getTableFilter(books, 'author');
    publishers = getTableFilter(books, 'publisher', true);
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <ContentLayout title="All Books">
      <Searchbar val={val} setVal={setVal} searchHandler={searchBooks} />
      <Table
        bordered
        dataSource={books}
        rowKey="id"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 10,
          total: books ? books.length : 0,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} books`;
          },
          showSizeChanger: false,
        }}
        loading={{ spinning: requesting, indicator: antIcon }}
      >
        <Table.Column
          title="Id"
          dataIndex="id"
          sorter={(a: IBook, b: IBook) => a.id - b.id}
        />
        <Table.Column
          title="Title"
          dataIndex="title"
          sorter={(a: IBook, b: IBook) => a.id - b.id}
        />
        <Table.Column
          title="Author"
          dataIndex="author"
          sorter={(a: IBook, b: IBook) =>
            a.author > b.author ? -1 : b.author > a.author ? 1 : 0
          }
          filters={authors}
          onFilter={(value, record) =>
            typeof value === 'string' && record.author.includes(value)
          }
        />
        <Table.Column
          title="Publisher"
          sorter={(a: IBook, b: IBook) =>
            a.publisher.name > b.publisher.name
              ? -1
              : b.publisher.name > a.publisher.name
              ? 1
              : 0
          }
          filters={publishers}
          onFilter={(value, record) =>
            typeof value === 'string' && record.publisher.name.includes(value)
          }
          render={(book) => <div>{book.publisher.name}</div>}
        />
        <Table.Column
          title="Actions"
          render={(_, book) => (
            <div>
              <Popover
                content={
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <AddBOTW book_id={book.id} />
                    <AddFreeBook bookId={book.id} />
                    <AddBookTag
                      book={bookToUpdate}
                      onFinish={() => getBooks({ setRequesting, setBooks })}
                    />
                  </Space>
                }
                title="Add Actions"
                trigger="click"
              >
                <PlusCircleTwoTone />
              </Popover>
              <Popover
                content={
                  <RemoveBookTag
                    book={bookToUpdate}
                    onFinish={() => getBooks({ setRequesting, setBooks })}
                  />
                }
                title="Remove tag?"
                trigger="click"
              >
                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ marginLeft: '0.75rem' }}
                />
              </Popover>
            </div>
          )}
          onCell={(record: IBook) => {
            return {
              onClick: () => {
                setBookToUpdate(record);
              },
            };
          }}
        />
        <Table.Column
          title="Categories"
          filters={categories.map((el) => ({ text: el.tag, value: el.tag }))}
          onFilter={(value: any, record: any) =>
            typeof value === 'string' && record.categories.includes(value)
          }
          render={(book) =>
            book &&
            book.categories && (
              <>
                {book.categories.split(', ').map((tag: string) => (
                  <Tag color={color[Math.floor(Math.random() * 10)]} key={tag}>
                    {tag}
                  </Tag>
                ))}
              </>
            )
          }
        />
        <Table.Column
          title="Age"
          filters={ages.map((el) => ({ text: el.tag, value: el.tag }))}
          onFilter={(value: any, record: any) =>
            typeof value === 'string' && record.age.includes(value)
          }
          render={(book) =>
            book &&
            book.age && (
              <>
                {book.age.split(', ').map((tag: string) => (
                  <Tag color={color[Math.floor(Math.random() * 10)]} key={tag}>
                    {tag}
                  </Tag>
                ))}
              </>
            )
          }
        />
        <Table.Column
          title="Featured"
          filters={featuredTags.map((el) => ({ text: el.tag, value: el.tag }))}
          onFilter={(value: any, record: any) =>
            typeof value === 'string' && record.curated.includes(value)
          }
          render={(book) =>
            book &&
            book.curated && (
              <>
                {book.curated.split(', ').map((tag: string) => (
                  <Tag color={color[Math.floor(Math.random() * 10)]} key={tag}>
                    {tag}
                  </Tag>
                ))}
              </>
            )
          }
        />

        <Table.Column
          title="Active"
          render={(item) =>
            item.active ? (
              <Tag icon={<CheckCircleOutlined />} color="green">
                YES
              </Tag>
            ) : (
              <Tag icon={<CloseCircleOutlined />} color="red">
                NO
              </Tag>
            )
          }
        />
      </Table>
    </ContentLayout>
  );
};

export default Books;
