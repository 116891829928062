import React from 'react';
import { Button, Form, Input, InputNumber, Select, Tooltip } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import getCoupons from 'utils/getCoupons';
import { ICoupon } from 'types';
import { IOption, discountTypes, freeTrialUnits } from './options';

import ContentLayout from 'components/Layout';
import CouponTable from './CouponTable';

const Coupons = () => {
  const [coupons, setCoupons] = React.useState<ICoupon[]>([]);
  const [filtered, setFiltered] = React.useState<string>('');
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [discount_type, setDiscountType] = React.useState<string>('');
  const [, setFreeTrialUnit] = React.useState<string>('');

  const [requesting, setRequesting] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      getCoupons({ setRequesting, setCoupons });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // handlers
  const handleCouponInputChange = (e: any) => {
    if ('01lIOQ'.includes(e.nativeEvent.data)) {
      return setShowTooltip(true);
    }
    setShowTooltip(false);
    setFiltered(e.target.value);
  };

  const onDiscountTypeChange = (value: string) => {
    setDiscountType(value);
  };

  const onFreeTrialUnitChange = (value: string) => {
    setFreeTrialUnit(value);
  };

  const onFinish = async (data: ICoupon) => {
    setRequesting(true);
    try {
      // create new coupon
      await axiosInstance.post(`${config.baseUrl}/coupons`, {
        ...data,
        code: filtered,
      });
    } catch (error) {
      console.error('error: ', error);
    } finally {
      setRequesting(false);
      await getCoupons({ setRequesting, setCoupons });
      setFiltered('');
      return setVisible(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error(errorInfo);
  };

  const deactivateCoupon = async (coupon_id: string) => {
    setRequesting(true);
    try {
      // delete specified coupon
      await axiosInstance.patch(
        `${config.baseUrl}/coupons/${coupon_id}?status=0`,
      );
      // fetch all coupons
      return getCoupons({ setRequesting, setCoupons });
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  const activateCoupon = async (coupon_id: string) => {
    setRequesting(true);
    try {
      // delete specified coupon
      await axiosInstance.patch(
        `${config.baseUrl}/coupons/${coupon_id}?status=1`,
      );
      // fetch all coupons
      return getCoupons({ setRequesting, setCoupons });
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  return (
    <ContentLayout title={'Showing: All Coupons'}>
      {!visible && (
        <Button
          type="primary"
          style={{ marginBottom: '1rem' }}
          onClick={() => setVisible(true)}
        >
          Add Coupon
        </Button>
      )}
      {visible && (
        <div
          style={{
            marginBottom: '1rem',
          }}
        >
          <Form
            {...{ wrapperCol: { span: 10 } }}
            layout="inline"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              {...{ wrapperCol: { span: 25 } }}
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please enter the new coupon code!',
                },
              ]}
              style={{ marginBottom: '0.5rem' }}
              valuePropName={filtered}
            >
              <Tooltip
                placement="bottom"
                visible={showTooltip}
                destroyTooltipOnHide={true}
                title="Sorry, this character cannot be used in coupon name"
              >
                <Input
                  value={filtered}
                  allowClear={true}
                  placeholder={`Coupon code`}
                  onChange={(ev) => handleCouponInputChange(ev)}
                />
              </Tooltip>
            </Form.Item>
            <Form.Item
              {...{ wrapperCol: { span: 25 } }}
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message:
                    'Please enter a description for the new coupon code!',
                },
              ]}
            >
              <Input placeholder={`Description`} />
            </Form.Item>
            <Form.Item
              {...{ wrapperCol: { span: 20 } }}
              label="Discount Type"
              name="discount_type"
              rules={[
                {
                  required: true,
                  message: 'Please select a discount type',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 100 }}
                placeholder="Select a discount type"
                optionFilterProp="children"
                onChange={onDiscountTypeChange}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {discountTypes.map((el: IOption) => (
                  <Select.Option value={el.value} key={el.value}>
                    {el.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {discount_type === 'free_trial' && (
              <Form.Item
                {...{ wrapperCol: { span: 25 } }}
                label="Free Trial Unit"
                name="free_trial_unit"
                rules={[
                  {
                    required: true,
                    message: 'Please select a free trial unit',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 100 }}
                  placeholder="Select a free trial unit"
                  optionFilterProp="children"
                  onChange={onFreeTrialUnitChange}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {freeTrialUnits.map((el: IOption) => (
                    <Select.Option value={el.value} key={el.value}>
                      {el.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              {...{ wrapperCol: { span: 25 } }}
              label="Amount"
              name="free_trial_amount"
              rules={[
                {
                  required: true,
                  message: 'Please enter number of units for the coupon code!',
                },
              ]}
            >
              <InputNumber min={1} placeholder={`Amount`} />
            </Form.Item>
            <Form.Item {...{ wrapperCol: { offset: 0.5 } }}>
              <Button type="primary" htmlType="submit" disabled={requesting}>
                Create
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="default"
                disabled={requesting}
                onClick={() => setVisible(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <CouponTable
        coupons={coupons}
        requesting={requesting}
        deactivateCoupon={deactivateCoupon}
        activateCoupon={activateCoupon}
      />
    </ContentLayout>
  );
};

export default Coupons;
