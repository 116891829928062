import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IAdminUser } from '../types';

interface IAuthContext {
  user: IAdminUser | null;
  setLoggedInUser(value: IAdminUser): void;
}

export const AuthContext = React.createContext<IAuthContext>(null!);

export default function AuthContextProvider({ children }: any) {
  const history = useHistory<{ from: string }>();
  const [user, setUser] = useState<IAdminUser | null>(null);

  const setLoggedInUser = (currentUser: IAuthContext['user']) => {
    setUser(currentUser);
    history.push(history.location.state?.from || '/dashboard');
  };

  const value = {
    user,
    setLoggedInUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
