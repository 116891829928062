import React, { useState } from 'react';

import { IUser } from 'types';

interface IUsersData {
  meta: {
    hasNext: boolean;
    prevUrl: string | null;
    nextUrl: string | null;
    total: number;
    page: number;
  };
  data: IUser[];
}

interface IUserContext {
  users: IUsersData | null;
  selectedUser: IUser | null;
  setUser(user: IUser): void;
  setAllUsers(usersArr: IUsersData): void;
  val: string;
  setVal: React.Dispatch<React.SetStateAction<string>>;
}

export const UserContext = React.createContext<IUserContext>(null!);

export default function UserContextProvider({ children }: any) {
  const [users, setUsers] = useState<IUsersData | null>(null);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  const setAllUsers = (usersArr: IUsersData) => {
    setUsers(usersArr);
  };

  const [val, setVal] = React.useState<string>('');

  const setUser = (user: IUser) => {
    setSelectedUser(user);
  };

  const value = {
    users,
    setUser,
    setAllUsers,
    selectedUser,
    val,
    setVal,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
