import React, { useState } from 'react';

import { IBook } from 'types';

interface IBooksData {
  meta: {
    hasNext: boolean;
    prevUrl: string | null;
    nextUrl: string | null;
    total: number;
    page: number;
  };
  data: IBook[];
}

interface IBookContext {
  books: IBooksData | null;
  selectedBook: IBook | null;
  setBook(book: IBook): void;
  setAllBooks(booksArr: IBooksData): void;
}

export const BookContext = React.createContext<IBookContext>(null!);

export default function BookContextProvider({ children }: any) {
  const [books, setBooks] = useState<IBooksData | null>(null);
  const [selectedBook, setSelectedBook] = useState<IBook | null>(null);

  const setAllBooks = (booksArr: IBooksData) => {
    setBooks(booksArr);
  };

  const setBook = (book: IBook) => {
    setSelectedBook(book);
  };

  const value = {
    books,
    setBook,
    setAllBooks,
    selectedBook,
  };

  return <BookContext.Provider value={value}>{children}</BookContext.Provider>;
}
