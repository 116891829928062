import config from 'config';
import axiosInstance from 'utils/axios';
import { ICoupon } from 'types';

export interface IGetCouponsProps {
  setRequesting: (value: React.SetStateAction<boolean>) => void;
  setCoupons: (value: React.SetStateAction<ICoupon[]>) => void;
}

const getCoupons = async ({ setRequesting, setCoupons }: IGetCouponsProps) => {
  setRequesting(true);
  try {
    const allCoupons = await axiosInstance.get(`${config.baseUrl}/coupons`);
    setCoupons(allCoupons.data.data);
    setRequesting(false);
  } catch (error) {
    setRequesting(false);
    console.error('error: ', error);
  }
};

export default getCoupons;
