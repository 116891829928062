const color = [
  'geekblue',
  'green',
  'purple',
  'gold',
  'volcano',
  'orange',
  'pink',
  'red',
  'green',
  'blue',
  'purple',
];

export default color;
