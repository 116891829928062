import React from 'react';
import { Button, Table, Tag } from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { ICoupon } from 'types';

export interface ICouponTableProps {
  coupons: ICoupon[];
  requesting: boolean;
  deactivateCoupon: (param1: string) => void;
  activateCoupon: (param1: string) => void;
}

const CouponTable = (props: ICouponTableProps) => {
  const { coupons, requesting, deactivateCoupon, activateCoupon } = props;

  const activate = (coupon_id: string) => {
    activateCoupon(coupon_id);
  };

  const deactivate = (coupon_id: string) => {
    deactivateCoupon(coupon_id);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Table
      dataSource={coupons}
      rowKey="id"
      pagination={{
        position: ['bottomRight'],
        defaultPageSize: 10,
        total: coupons ? coupons.length : 0,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} of ${total} results`;
        },
        showSizeChanger: false,
      }}
      loading={{ spinning: requesting, indicator: antIcon }}
    >
      <Table.Column title="Code" dataIndex="code" key="id" />
      <Table.Column title="Description" dataIndex="description" key="id" />
      <Table.Column title="Discount Type" dataIndex="discount_type" key="id" />
      <Table.Column
        title="Free Trial Amount"
        dataIndex="free_trial_amount"
        key="id"
      />
      <Table.Column
        title="Free Trial Unit"
        dataIndex="free_trial_unit"
        key="id"
      />
      <Table.Column
        title="Active"
        render={(item) =>
          item.active ? (
            <Tag icon={<CheckCircleOutlined />} color="green">
              YES
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="red">
              NO
            </Tag>
          )
        }
      />

      <Table.Column
        title="Action"
        key="id"
        render={(item) =>
          item.active ? (
            <Button onClick={(e) => deactivate(item.code)}>Deactivate</Button>
          ) : (
            <Button onClick={(e) => activate(item.code)}>Activate</Button>
          )
        }
      />
    </Table>
  );
};

export default CouponTable;
