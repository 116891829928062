import moment from 'moment';

import config from 'config';
import axiosInstance from 'utils/axios';
import { ITag } from 'types';
import { IBook } from 'types';
import getBooks from './getBooks';

export interface IGetBOTWProps {
  setRequesting: (value: React.SetStateAction<boolean>) => void;
  setBotw: (value: React.SetStateAction<ITag[]>) => void;
  setBooks: (value: React.SetStateAction<IBook[]>) => void;
}

const getBOTW = async ({ setRequesting, setBotw, setBooks }: IGetBOTWProps) => {
  setRequesting(true);
  try {
    const allBOTW = await axiosInstance.get(`${config.baseUrl}/books/botw`);
    setBotw(
      allBOTW.data.data.map((el: any) => ({
        ...el,
        title: el.book ? el.book.title : '',
        start_date: moment(el.start_date)
          .tz('America/New_York')
          .format('ddd MMM Do YYYY HH:mm ZZ z'),
        end_date: moment(el.end_date)
          .tz('America/New_York')
          .format('ddd MMM Do YYYY HH:mm ZZ z'),
      })),
    );
    await getBooks({ addTags: false, setRequesting, setBooks });
    setRequesting(false);
  } catch (error) {
    setRequesting(false);
    console.error('error: ', error);
  }
};

export default getBOTW;
