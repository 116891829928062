import axios from 'axios';

import config from '../config';

const AUTH_ERROR = [401, 403];

const axiosInstance = axios.create({
  baseURL: config.baseUrl,
});

axiosInstance.defaults.withCredentials = true;

// export const apolloAxios = axios.create({
//   baseURL: appUrl,
// });

// apolloAxios.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response && AUTH_ERROR.includes(error.response.status)) {
      await axiosInstance.get('/auth/logout');
      window.location.href = '/';
      return;
    }
    return Promise.reject(error);
  },
);

// apolloAxios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     if (error.response && AUTH_ERROR.includes(error.response.status)) {
//       doLogout();
//       return;
//     }
//     return Promise.reject(error);
//   },
// );

export default axiosInstance;
