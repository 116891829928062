export interface IOption {
  title: string;
  value: string;
}

export const discountTypes: IOption[] = [
  {
    title: 'Free Trial',
    value: 'free_trial',
  },
  {
    title: 'Fixed',
    value: 'fixed',
  },
  {
    title: 'Percent',
    value: 'percent',
  },
];

export const freeTrialUnits: IOption[] = [
  {
    title: 'Day',
    value: 'day',
  },
  {
    title: 'Week',
    value: 'week',
  },
  {
    title: 'Month',
    value: 'month',
  },
];
