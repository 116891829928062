import React from 'react';
import { Layout, Row, Col, Menu, Button, Dropdown } from 'antd';
import { LogoutOutlined, DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from '../../hooks';
import Logo from '../../assets/images/readeo-logo.png';
import axiosInstance from '../../utils/axios';

function Header() {
  const history = useHistory();
  const { user } = useAuthContext();

  const logout = async () => {
    try {
      await axiosInstance.get('/auth/logout');
      history.push('/');
    } catch (error) {}
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<LogoutOutlined />} onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Header className="header">
      <Row>
        <Col flex="2">
          <div className="logo">
            <img src={Logo} alt="readeo" />
          </div>
        </Col>
        <Col>
          {user && (
            <Dropdown overlay={menu}>
              <Button type="text" style={{ color: 'white' }}>
                {user.last_name}, {user.first_name}
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Col>
      </Row>
    </Layout.Header>
  );
}

export default Header;
