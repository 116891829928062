import React, { useEffect, useState } from 'react';
import { Tabs, Button } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import config from 'config';
import axiosInstance from 'utils/axios';
import { useUserContext } from 'hooks/useUserContext';

import ContentLayout from 'components/Layout';
import UserProfile from './UserProfile';
import UserHistory from './UserHistory';
import UserBookChats from './UserBookChats';
import UserBuddies from './UserBuddies';
import UserSubscriptions from './UserSubscriptions';
import UserActions from './UserActions';
import UserProgress from './UserProgress';

const { TabPane } = Tabs;

const Profile = () => {
  let { userId } = useParams<{ userId: string }>();
  const [details, setDetails] = useState();
  const [chats, setChats] = useState();
  const [buddies, setBuddies] = useState();
  const [progress, setProgress] = useState([]);

  const history = useHistory();

  const { selectedUser, setUser } = useUserContext();

  const getUserDetails = async (page = 1) => {
    try {
      let details = await axiosInstance.get(
        `${config.baseUrl}/users/${userId}/login-histories?page=${page}&limit=10`,
      );
      setDetails(details.data?.data[0]);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const getUserChats = async (page = 1) => {
    try {
      let details = await axiosInstance.get(
        `${config.baseUrl}/users/${userId}/bookchat-invites?page=${page}&limit=10`,
      );
      setChats(details.data?.data[0]);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const getUserBuddies = async () => {
    try {
      let buddies = await axiosInstance.get(
        `${config.baseUrl}/users/${userId}/buddies`,
      );
      setBuddies(buddies.data?.data);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const getUserInfo = async () => {
    try {
      let user = await axiosInstance.get(`${config.baseUrl}/users/${userId}`);
      setUser(user.data?.data[0]);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const getUserProgress = async () => {
    try {
      let user = await axiosInstance.get(
        `${config.baseUrl}/users/${userId}/progress`,
      );
      setProgress(user.data?.data);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  useEffect(() => {
    if (!userId) return;

    getUserDetails();
    getUserChats();
    getUserBuddies();
    getUserInfo();
    getUserProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const onUpdate = async () => {
    getUserInfo();
  };

  return (
    <ContentLayout
      title={`${
        selectedUser
          ? `${selectedUser.first_name} ${selectedUser.last_name}`
          : ''
      }`}
    >
      <Button
        onClick={() => history.goBack()}
        style={{
          marginBottom: '1rem',
        }}
      >
        Back
      </Button>

      <Tabs type="card">
        <TabPane tab="User Profile" key="1">
          <UserProfile />
        </TabPane>
        <TabPane tab="Login History" key="2">
          <UserHistory
            details={details}
            userId={userId}
            getUserDetails={getUserDetails}
          />
        </TabPane>
        <TabPane tab="BookChat History" key="3">
          <UserBookChats chats={chats} getUserchats={getUserChats} />
        </TabPane>
        <TabPane tab="Buddies" key="40">
          <UserBuddies buddies={buddies} />
        </TabPane>
        <TabPane tab="Subscription History" key="5">
          <UserSubscriptions userId={userId} />
        </TabPane>
        <TabPane tab="Profile Progress" key="6">
          <UserProgress progress={progress} userId={userId} />
        </TabPane>
        <TabPane tab="Actions" key="7">
          <UserActions user={selectedUser} onUpdate={onUpdate} />
        </TabPane>
      </Tabs>
    </ContentLayout>
  );
};

export default Profile;
