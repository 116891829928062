const getTableFilter = (arr: any, prop: string, hasName: boolean = false) => {
  return arr
    .reduce(
      (accumulator: any, curVal: any) =>
        accumulator.includes(hasName ? curVal[prop]['name'] : curVal[prop])
          ? [...accumulator]
          : [...accumulator, hasName ? curVal[prop]['name'] : curVal[prop]],
      [],
    )
    .map((el: any) => ({ text: el, value: el }));
};

export default getTableFilter;
