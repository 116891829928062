import React from 'react';
import { Row, Col, Divider } from 'antd';
import { IStat } from 'types';
import SubStats from './SubStats';
import BookStats from './BookStats';
import BookChatStats from './BookChatStats';
import UserStats from './UserStats';
import GiftCardStats from './GiftCardStats';
import FreeUserMetrics from './FreeUserStats';

export default function Summary({ stats }: { stats: IStat }) {
  return (
    <div className="site-statistic-demo-card">
      <Row gutter={20}>
        <Col span={11} className="stat-card">
          <Divider type="horizontal" orientation="center" plain>
            USERS
          </Divider>
          <UserStats data={stats.users} />
        </Col>
        <Col span={11} className="stat-card">
          <Divider type="horizontal" orientation="center" plain>
            Free User Metrics
          </Divider>
          <FreeUserMetrics data={stats.free_user_metrics} />
        </Col>
        <Col span={11} className="stat-card">
          <Divider type="horizontal" orientation="center" plain>
            SUBSCRIPTIONS
          </Divider>
          <SubStats data={stats.subs} />
        </Col>
        <Col span={11} className="stat-card">
          <Divider type="horizontal" orientation="center" plain>
            BOOKS
          </Divider>
          <BookStats data={stats.books} />
        </Col>
        <Col span={11} className="stat-card">
          <Divider type="horizontal" orientation="center" plain>
            BOOKCHATS
          </Divider>
          <BookChatStats data={stats.bookchats} />
        </Col>
        <Col span={11} className="stat-card">
          <Divider type="horizontal" orientation="center" plain>
            GIFTCARD
          </Divider>
          <GiftCardStats data={stats.giftcard} />
        </Col>
      </Row>
    </div>
  );
}
