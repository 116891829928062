import React, { useState } from 'react';
import { Form, Input, Button, Typography, message, Modal } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import ContentLayout from 'components/Layout';

const { Paragraph, Text, Title } = Typography;

interface IStatus {
  isLoading: boolean;
  errors: {
    first_name?: string;
    username?: string;
    last_name?: string;
  } | null;
}

interface IUserInfo {
  first_name: string;
  username: string;
  last_name: string;
  password: string;
}

function CreateUser() {
  const [form] = Form.useForm();

  const [status, setStatus] = useState<IStatus>({
    isLoading: false,
    errors: null,
  });

  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);

  const onFinish = async (values: any) => {
    setStatus({ isLoading: true, errors: null });
    try {
      const res = await axiosInstance.post(
        `${config.baseUrl}/users/create-vip`,
        values,
      );

      if (res) {
        setUserInfo({ ...values, ...res.data.data[0] });
        form.resetFields();
        message.success({
          content: 'User created successfully',
        });
        setStatus({ isLoading: false, errors: null });
      }
    } catch (error) {
      setStatus((prev) => ({
        ...prev,
        isLoading: false,
      }));

      if (error.response.status === 422 && error.response.data?.errors) {
        setStatus((prev) => ({
          ...prev,
          errors: error.response.data.errors,
        }));
      } else if (
        error.response.status === 409 &&
        error.response.data?.message
      ) {
        setStatus((prev) => ({
          ...prev,
          errors: {
            username: error.response.data.message,
          },
        }));
      } else {
        message.error({
          content: 'Error creating user account.',
        });
      }
    }
  };

  return (
    <ContentLayout title="Create VIP User">
      <Form
        layout="vertical"
        name="create-user"
        form={form}
        onFinish={onFinish}
        style={{ maxWidth: '480px' }}
      >
        <Form.Item
          hasFeedback
          label="Firstname"
          name="first_name"
          rules={[{ required: true, message: 'firstname field is required!' }]}
          {...(status.errors?.first_name && {
            validateStatus: 'error',
            help: status.errors.first_name,
          })}
        >
          <Input size="large" placeholder="input firstname" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Lastname"
          name="last_name"
          rules={[{ required: true, message: 'lastname field is required!' }]}
          {...(status.errors?.last_name && {
            validateStatus: 'error',
            help: status.errors.last_name,
          })}
        >
          <Input size="large" placeholder="input lastname" />
        </Form.Item>
        <Form.Item
          label="Email Address"
          hasFeedback
          name="username"
          className="form-input"
          rules={[
            { required: true, message: 'email field is required!' },
            {
              type: 'email',
              message: 'The input is not valid e-mail!',
            },
          ]}
          {...(status.errors?.username && {
            validateStatus: 'error',
            help: status.errors.username,
          })}
        >
          <Input size="large" type="email" placeholder="input email address" />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            loading={status.isLoading}
          >
            Create User
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="User created successfully"
        centered
        visible={!!userInfo}
        maskClosable={false}
        onCancel={() => setUserInfo(null)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setUserInfo(null)}>
            Ok
          </Button>,
        ]}
      >
        {userInfo && (
          <>
            <Title level={5}>Fullname:</Title>
            <Text style={{ fontSize: '1.3rem' }}>
              {userInfo.first_name} {userInfo.last_name}
            </Text>

            <Title level={5}>email:</Title>
            <Text style={{ fontSize: '1.3rem' }}>{userInfo.username}</Text>

            <Title level={5}>Password:</Title>
            <Text type="secondary">Copy generated user password</Text>
            <Paragraph copyable={{ text: userInfo.password }}>
              <Text underline strong code style={{ fontSize: '1.6rem' }}>
                {userInfo.password}
              </Text>
            </Paragraph>
          </>
        )}
      </Modal>
    </ContentLayout>
  );
}

export default CreateUser;
