import config from 'config';
import axiosInstance from 'utils/axios';

export interface IProps {
  setRequesting: (value: React.SetStateAction<boolean>) => void;
  setBookshelves: (value: React.SetStateAction<any[]>) => void;
}

const getBOTW = async ({ setRequesting, setBookshelves }: IProps) => {
  setRequesting(true);
  try {
    const response = await axiosInstance.get(`${config.baseUrl}/bookshelves?q=readeo`);
    if (response.data.data) {
       setBookshelves(response.data.data);
    }
  } catch (error) {
    console.error('error: ', error);
  } finally {
    setRequesting(false);
  }
};

export default getBOTW;
