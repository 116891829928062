import React from 'react';
import { Statistic, Row, Col, Popover } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { IBookChatStats } from 'types';

export default function BookChatStats({ data }: { data: IBookChatStats }) {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Statistic title="Total Bookchats" value={data.total_bookchats} />
      </Col>
      <Col span={6}>
        <Statistic title="Total Bookreads" value={data.total_bookreads} />
      </Col>
      <Col span={6}>
        <Popover content="BookChats in the past 24hrs" placement="top">
          <Statistic
            title="New Bookchats"
            value={data.curr_bookchats}
            valueStyle={{ color: '#3f8600' }}
            prefix={<ArrowUpOutlined />}
          />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content="Book reads in the past 24hrs" placement="top">
          <Statistic
            title="New Bookreads"
            value={data.curr_bookreads}
            valueStyle={{ color: '#3f8600' }}
            prefix={<ArrowUpOutlined />}
          />
        </Popover>
      </Col>
    </Row>
  );
}
