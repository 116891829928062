import config from 'config';
import axiosInstance from 'utils/axios';

export interface IGetLatestProps {
  setRequesting: (value: React.SetStateAction<boolean>) => void;
  setLatest: (value: React.SetStateAction<string>) => void;
}

const getLatest = async ({ setRequesting, setLatest }: IGetLatestProps) => {
  setRequesting(true);
  try {
    const response = await axiosInstance.get(
      `${config.baseUrl}/books/botw/latest`,
    );
    setLatest(response.data.data[0]);
    setRequesting(false);
  } catch (error) {
    setRequesting(false);
    console.error('error: ', error);
  }
};

export default getLatest;
