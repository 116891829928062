import { Environments, Config } from './config.types';

const config: Config = {
  [Environments.development]: {
    baseUrl: 'http://localhost:3344/api',
    assetsUrl: 'https://testassets.readeo.com',
    avatarUrl: 'https://testavatars.readeo.com',
    appUrl: "https://account.local.readeo.com",
  },
  [Environments.qa]: {
    baseUrl: 'https://cs.test.readeo.com/api',
    assetsUrl: 'https://testassets.readeo.com',
    avatarUrl: 'https://testavatars.readeo.com',
    appUrl: "https://app.test.readeo.com",
  },
  [Environments.production]: {
    baseUrl: 'https://cs.readeo.com/api',
    assetsUrl: 'https://assets.readeo.com',
    avatarUrl: 'https://avatars.readeo.com',
    appUrl: 'https://app.readeo.com',
  },
};

const environment =
  (process.env.REACT_APP_ENV as Environments) || Environments.development;

export default config[environment];
