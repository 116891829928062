import React, { Key, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Space, Tag } from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { default as dayjs } from 'dayjs';

import config from 'config';
import axiosInstance from 'utils/axios';
import { useUserContext } from 'hooks/useUserContext';
import Searchbar from 'components/shared/Searchbar';
import ContentLayout from 'components/Layout';

const Users = () => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [loading, setLoading] = React.useState(true);
  const { setAllUsers, users, setUser, val, setVal } = useUserContext();
  const [filters, setFilters] = React.useState<Record<string, Key[] | null>>({
    account_type: null,
    3: null,
  });
  const [currentPage, setCurrentPage] = React.useState(
    parseInt(params.get('page') || '1', 10),
  );

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('page', currentPage.toString());
    window.history.pushState(null, '', url.toString());
  }, [currentPage]);

  const searchHandler = async () =>
    // if value is a valid Number, search user by id, else search by text
    !isNaN(Number(val))
      ? getUserById(Number(val))
      : getUsers(
          1,
          filters.fpstatus ? filters.fpstatus.join(',') : undefined,
          filters.account_type ? filters.account_type.join(',') : undefined,
        );

  const getUsers = async (page = 1, fpstatus?: any, account_type?: any) => {
    try {
      setLoading(true);
      const allUsers = await axiosInstance.get(
        `${config.baseUrl}/users?page=${page}&limit=10${
          fpstatus !== undefined ? `&fpstatus=${fpstatus}` : ''
        }${account_type !== undefined ? `&account_type=${account_type}` : ''}${
          val ? `&search=${encodeURIComponent(val)}` : ''
        }`,
      );
      setAllUsers(allUsers.data?.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const getUserById = async (id: number) => {
    try {
      let user = await axiosInstance.get(`${config.baseUrl}/users/${id}`);
      const meta = {
        hasNext: false,
        prevUrl: null,
        nextUrl: null,
        total: 1,
        page: 1,
      };
      setAllUsers({ meta: meta, data: user.data?.data });
    } catch (error) {
      console.error('error: ', error);
    }
  };

  React.useEffect(() => {
    getUsers(
      currentPage,
      filters.fpstatus ? filters.fpstatus.join(',') : undefined,
      filters.account_type ? filters.account_type.join(',') : undefined,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const subscriptions = [
    { text: 'Guest', value: 0 },
    { text: 'Active', value: 1 },
    { text: 'VIP', value: 8 },
    { text: 'Teacher', value: 'teacher' },
    { text: 'Club', value: 10 },
  ];

  const familyPlan = [
    { text: 'YES', value: true },
    { text: 'NO', value: false },
  ];

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <ContentLayout title="All Users">
      <Searchbar val={val} setVal={setVal} searchHandler={searchHandler} />
      <Table
        bordered
        dataSource={users?.data}
        rowKey="id"
        pagination={{
          current: currentPage,
          position: ['bottomRight'],
          defaultPageSize: 10,
          total: users ? users.meta.total : 0,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} users`;
          },
          onChange: (page) => {
            // sets the current page to state, for use in the request
            setCurrentPage(page);
          },
          showSizeChanger: false,
        }}
        loading={{ spinning: loading, indicator: antIcon }}
        onChange={(_, _filters, __, ___) => {
          setFilters(_filters);
        }}
        onRow={(record) => {
          return {
            className: 'clickable',
            onClick: () => {
              setUser(record);
              history.push(`/dashboard/profile/${record.id}`);
            },
          };
        }}
      >
        <Table.Column title="User ID" render={(user) => <div>{user.id}</div>} />
        <Table.Column
          title="Full Name"
          render={(user) => (
            <div>
              {user.first_name} {user.last_name}
            </div>
          )}
        />
        <Table.Column title="Email Address" dataIndex="username" />
        <Table.Column
          title="Subscription"
          dataIndex="account_type"
          filters={subscriptions}
          onFilter={(value, record) => {
            if (value === record.account_type) {
              return true;
            } else if (
              value === 'teacher' &&
              record?.roles?.some((role: any) => {
                return role.role === 'SCHOOL_TEACHER';
              })
            ) {
              return true;
            }
            return false;
          }}
          render={(text: any, row: any) => {
            if (text === 0) {
              return <Tag color="default">GUEST</Tag>;
            } else if (text === 8) {
              return row?.roles?.some((role: any) => {
                return role.role === 'SCHOOL_TEACHER';
              }) ? (
                <Tag color="blue">TEACHER</Tag>
              ) : (
                <Tag color="blue">VIP</Tag>
              );
            } else if (text === 10) {
              <Tag color="purple">CLUB</Tag>;
            } else {
              return (
                <Tag icon={<CheckCircleOutlined />} color="green">
                  ACTIVE
                </Tag>
              );
            }
          }}
        />
        <Table.Column
          title="Family Plan"
          dataIndex="fpstatus"
          filters={familyPlan}
          render={(fpstatus) =>
            fpstatus ? (
              <Tag icon={<CheckCircleOutlined />} color="green">
                YES
              </Tag>
            ) : (
              <Tag icon={<CloseCircleOutlined />} color="red">
                NO
              </Tag>
            )
          }
        />
        <Table.Column
          title="Joined"
          render={(user) => (
            <Space size="large" align="baseline">
              {dayjs(user.create_date).format('MM-DD-YYYY h:MM A')}
            </Space>
          )}
        />
        <Table.Column
          title="Bookchat Room"
          render={(user) => (
            <a
              href={`${config.appUrl}/room/${user.activationKey}`}
              target="_blank"
              rel="noreferrer"
            >{`${config.appUrl}/room/${user.activationKey}`}</a>
          )}
          ellipsis={true}
        />
        <Table.Column
          title="Buddy Invite Link"
          render={(user) => (
            <a
              href={`${config.appUrl}/signup?code=${user.buddy_invite_code}`}
              target="_blank"
              rel="noreferrer"
            >{`${config.appUrl}?code=${user.buddy_invite_code}`}</a>
          )}
          ellipsis={true}
        />

        {/* <Table.Column
          title="Action"
          key="action"
          render={(item) => (
            <Space size="middle">
              <Popover
                trigger="click"
                content={() => (
                  <Button
                    danger
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setVal('');
                      deleteUser(item.id);
                    }}
                    disabled={isRemoving}
                    loading={isRemoving}
                  >
                    Confirm delete
                  </Button>
                )}
                title="Delete user"
              >
                <Button
                  danger
                  size="small"
                  disabled={isRemoving}
                  onClick={(e) => {
                    setVal('');
                    e.stopPropagation();
                  }}
                >
                  Delete
                </Button>
              </Popover>
            </Space>
          )}
        /> */}
      </Table>
    </ContentLayout>
  );
};

export default Users;
