import React, { useCallback } from 'react';
import { Popover, Table, Button, Form, Input, Space, Tag, message } from 'antd';
import { IBookshelf } from 'types/bookshelf';
import { IBook } from 'types';
import {
  LoadingOutlined,
  DeleteTwoTone,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
} from '@ant-design/icons';
import { GradeType } from './type';
import getBookshelves from 'utils/getBookshelves';
import getBooks from 'utils/getBooks';
import ContentLayout from 'components/Layout';
import axiosInstance from 'utils/axios';
import config from 'config';
import AddBooks from './AddBooks';
import RemoveBooks from './RemoveBooks';
import AttachGrade from './AttachGrade';
const Bookshelves = () => {
  const [bookshelf, setBookshelf] = React.useState<IBookshelf | null>(null);
  const [bookshelves, setBookshelves] = React.useState<IBookshelf[]>([]);
  const [books, setBooks] = React.useState<IBook[]>([]);
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);

  const [grades, setGrades] = React.useState<GradeType[]>([]);

  const onFinish = async ({ name }: { name: string }) => {
    setRequesting(true);
    try {
      const res = await axiosInstance.post(`${config.baseUrl}/bookshelves`, {
        name,
      });
      if (res.data.data) {
        setBookshelves([...bookshelves, res.data.data[0]]);
      }
      return setVisible(false);
    } catch (error) {
      console.error('error: ', error);
    } finally {
      setRequesting(false);
    }
  };

  const deleteBookshelf = async (id: number) => {
    setRequesting(true);
    try {
      await axiosInstance.delete(`${config.baseUrl}/bookshelves/${id}`);
      getBookshelves({ setRequesting, setBookshelves });
      return setVisible(false);
    } catch (error) {
      console.error('error: ', error);
    } finally {
      setRequesting(false);
    }
  };

  const detachGrade = async (bookshelfId: number, gradeId: number) => {
    try {
      await axiosInstance.patch(
        `${config.baseUrl}/schools/grades/detach-bookshelf`,
        { bookshelfId, gradeId },
      );
      message.success('Grade successfully detached from bookshelf');
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const detachBook = async (bookshelfId: number, bookId: number) => {
    setRequesting(true);
    try {
      await axiosInstance.patch(
        `${config.baseUrl}/bookshelves/${bookshelfId}/books`,
        {
          bookIds: [bookId],
        },
      );
      message.success('Book successfully detached from bookshelf');
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    } finally {
      setRequesting(false);
    }
  };

  const filterBookshelfBooks = useCallback(() => {
    if (!bookshelf || !bookshelf.books) return books;
    const bookshelfBookIds = bookshelf.books.map((book) => book.id);
    return books.filter((book) => !bookshelfBookIds.includes(book.id));
  }, [bookshelf, books]);

  React.useEffect(() => {
    getBookshelves({ setRequesting, setBookshelves });
    getBooks({ setRequesting, setBooks });
    const fetchGrades = async () => {
      try {
        const { data } = await axiosInstance.get(
          `${config.baseUrl}/schools/grades`,
        );
        console.log(data);
        setGrades(data.data);
      } catch (error) {
        console.error('error: ', error);
      }
    };
    fetchGrades();
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const DeleteContent = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        type="primary"
        disabled={requesting}
        onClick={() => bookshelf && deleteBookshelf(bookshelf.id)}
      >
        Delete
      </Button>
    </div>
  );
  return (
    <ContentLayout title="System bookshelves">
      <Space style={{ marginBottom: '1rem' }}>
        {!visible && (
          <Button type="primary" onClick={() => setVisible(true)}>
            Add Bookshelf
          </Button>
        )}
        {visible && (
          <Form
            {...{ wrapperCol: { span: 10 } }}
            layout="inline"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              {...{ wrapperCol: { span: 25 } }}
              label="name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the bookshelf!',
                },
              ]}
            >
              <Input placeholder="Bookshelf name" />
            </Form.Item>
            <Form.Item {...{ wrapperCol: { offset: 2.5 } }}>
              <Button type="primary" htmlType="submit" disabled={requesting}>
                Create
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="default"
                disabled={requesting}
                onClick={() => setVisible(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        )}
        <AttachGrade
          grades={grades}
          bookshelves={bookshelves}
          onFinish={() => getBookshelves({ setRequesting, setBookshelves })}
        />
      </Space>
      <Table
        dataSource={bookshelves}
        rowKey="id"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 10,
          total: bookshelves.length ? bookshelves.length : 0,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          showSizeChanger: false,
        }}
        loading={{ spinning: requesting, indicator: antIcon }}
      >
        <Table.Column title="Name" dataIndex="name" key="id" width="25%" />
        <Table.Column
          title="Books"
          width="40%"
          render={(_, record: IBookshelf) =>
            record?.books?.map((book) => (
              <Tag
                closable
                key={book.id}
                onClose={() => detachBook(record.id, book.id)}
              >
                {book.title}
              </Tag>
            ))
          }
        />
        <Table.Column
          title="Attached Grades"
          width="20%"
          render={(_, record: IBookshelf) =>
            record?.grades?.map((grade) => (
              <Tag
                closable
                key={grade.id}
                onClose={() => detachGrade(record.id, grade.id)}
              >
                {grade.name}
              </Tag>
            ))
          }
        />
        <Table.Column
          width="15%"
          title="Actions"
          render={() => (
            <div>
              <Popover
                content={
                  <AddBooks
                    id={bookshelf?.id}
                    books={filterBookshelfBooks()}
                    onFinish={() =>
                      getBookshelves({ setRequesting, setBookshelves })
                    }
                  />
                }
                title="Attach Books"
                trigger="click"
              >
                <PlusCircleTwoTone />
              </Popover>
              <Popover
                content={
                  <RemoveBooks
                    id={bookshelf?.id}
                    books={bookshelf?.books || []}
                    onFinish={() =>
                      getBookshelves({ setRequesting, setBookshelves })
                    }
                  />
                }
                title="Detach Books"
                trigger="click"
              >
                <MinusCircleTwoTone
                  twoToneColor="#eb2f96"
                  style={{ marginLeft: '0.75rem' }}
                />
              </Popover>
              <Popover
                content={DeleteContent}
                title="Delete Bookshelf?"
                trigger="click"
              >
                <DeleteTwoTone
                  twoToneColor="#eb2f96"
                  style={{ marginLeft: '0.75rem' }}
                />
              </Popover>
            </div>
          )}
          onCell={(record: IBookshelf) => {
            return {
              onClick: () => setBookshelf(record),
            };
          }}
        />
      </Table>
    </ContentLayout>
  );
};

export default Bookshelves;
