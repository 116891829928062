import React, { useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import ContentLayout from 'components/Layout';
import { IStat } from 'types';
import axios from 'utils/axios';

import StatsSummary from './Summary';

import MonthlySubChart from './MonthlySubChart';
import MonthlyUserChart from './MonthlyUserChart';
function Stats() {
  const [stats, setStats] = useState<IStat | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get('/analytics/stats')
      .then((response) => {
        if (response.status === 200) {
          setStats(response.data.data[0]);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  return (
    <ContentLayout title="Stats">
      {loading && <Skeleton />}
      {stats && <StatsSummary stats={stats} />}
      <Row>
        <Col span="24">{<MonthlySubChart />}</Col>
      </Row>
      <Row>
        <Col span="24">{<MonthlyUserChart />}</Col>
      </Row>
    </ContentLayout>
  );
}

export default Stats;
