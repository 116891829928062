import React, { useEffect, useState } from 'react';
import { Route, Switch,  } from 'react-router-dom';
import { Spin } from 'antd';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import config from '../config';
import { useAuthContext } from '../hooks';
import AuthLayout from '../components/shared/AuthLayout';
import ProtectedRoute from './ProtectedRoute';
import axios from 'axios';

export default function AppRoute() {
  const [isLoading, setLoading] = useState(false);

  const { user, setLoggedInUser } = useAuthContext();

  const axiosInstance = axios.create({
    baseURL: config.baseUrl,
  });

  axiosInstance.defaults.withCredentials = true;

  useEffect(() => {
    setLoading(true);
    const fetchCurrentUser = async () => {
      try {
        const { data } = await axiosInstance.get(`${config.baseUrl}/auth`);
        if (data) {
          setLoggedInUser(data.data[0]);
        }
      } catch (error) {}
      setLoading(false);
    };

    fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading && !user) {
    return (
      <AuthLayout>
        <Spin size="large" />
      </AuthLayout>
    );
  }

  return (
    <Switch>
      <Route path="/" exact>
        <Login />
      </Route>

      <ProtectedRoute path="/dashboard" component={Dashboard} />
    </Switch>
  );
}
