import React, { useState } from 'react';
import { Table, Space, Tag, Modal } from 'antd';
import { LoadingOutlined, ChromeOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { default as dayjs } from 'dayjs';
import { FaSafari, FaFirefox } from 'react-icons/fa';

import config from 'config';
import axiosInstance from 'utils/axios';

const { Column } = Table;

const FirefoxIcon = (props: any) => <Icon component={FaFirefox} {...props} />;

interface IUserHistory {
  details: any;
  userId: string;
  getUserDetails(page?: number): void;
}

const UserHistory = ({ details, userId, getUserDetails }: IUserHistory) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [device, setDevice] = useState<any>(null);

  const getDeviceDetailsPerLogin = async (id: number) => {
    setLoading(true);
    setIsModalVisible(true);
    try {
      let {
        data: { data },
      } = await axiosInstance.get(
        `${config.baseUrl}/users/${userId}/login-histories/${id}`,
      );
      setLoading(false);
      setDevice(data);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const handleCancel = () => {
    setDevice(null);
    setIsModalVisible(false);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Modal
        title="Device Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {loading && <p>Loading...</p>}
        {!loading && device && !!device.length && (
          <>
            <p>
              Screen Width: <strong>{device[0].screen_width}px</strong>
            </p>
            <p>
              Screen Height: <strong>{device[0].screen_height}px</strong>
            </p>
            <p>
              Audio: <strong>{device[0].audio ? 'YES' : 'NO'}</strong>
            </p>
            <p>
              Video: <strong>{device[0].video ? 'YES' : 'NO'}</strong>
            </p>
            <p>
              WebGL Support: <strong>{device[0].webgl ? 'YES' : 'NO'}</strong>
            </p>
            <p>
              Pusher Connection:
              <strong>{device[0].pusher ? 'YES' : 'NO'}</strong>
            </p>
            <p>
              Client Version: <strong>{device[0].client_version}</strong>
            </p>
          </>
        )}
        {!loading && device && !device.length && <p>No device data found!</p>}
      </Modal>
      <Table
        dataSource={details?.data}
        rowKey="id"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 10,
          total: details ? details.meta.total : 0,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          onChange: (page) => {
            getUserDetails(page);
          },
          current: details ? details.meta.page : 1,
          showSizeChanger: false,
        }}
        rowClassName="row"
        loading={{ spinning: !details, indicator: antIcon }}
        onRow={(record) => {
          return {
            onClick: () => {
              getDeviceDetailsPerLogin(record.id);
            },
          };
        }}
      >
        <Column
          title="Browser"
          render={(item) => (
            <Space size="large" align="baseline">
              {/* {item.userAgent?.browser_name} */}
              {item.userAgent?.browser_name.includes('Chrome') ? (
                <>
                  <ChromeOutlined twoToneColor="#eb2f96" />{' '}
                  {item.userAgent?.browser_name}
                </>
              ) : item.userAgent?.browser_name.includes('Firefox') ? (
                <>
                  <FirefoxIcon color="red" /> {item.userAgent?.browser_name}
                </>
              ) : item.userAgent?.browser_name.includes('Safari') ? (
                <>
                  <FaSafari /> {item.userAgent?.browser_name}
                </>
              ) : (
                item.userAgent?.browser_name
              )}
              {item.userAgent?.browser_version}
            </Space>
          )}
        />
        <Column
          title="Device"
          render={(item) => (
            <Space size="large" align="baseline">
              <div>
                {item.userAgent?.os_name} {item.userAgent?.device_type}
              </div>
            </Space>
          )}
        />
        <Column
          title="Version"
          render={(item) => (
            <Space size="large" align="baseline">
              <div>{item.client_version}</div>
            </Space>
          )}
        />
        <Column
          title="DeviceId"
          render={(item) => (
            <Space size="large" align="baseline">
              <div>{item.device_id}</div>
            </Space>
          )}
        />
        <Column
          title="Event"
          render={(item) => (
            <Space size="large" align="baseline">
              {item.event_name === 'LogoutSuccessEvent' ? (
                <Tag color="default">Logout</Tag>
              ) : item.event_name === 'AuthenticationSuccessEvent' ? (
                <Tag color="success">CorrectPassword</Tag>
              ) : item.event_name === 'RefreshTokenSuccess' ? (
                <Tag color="success">CorrectToken</Tag>
              ) : item.event_name ===
                'AuthenticationFailureBadCredentialsEvent' ? (
                <Tag color="red">IncorrectPassword</Tag>
              ) : (
                <Tag color="blue">{item.event_name}</Tag>
              )}
            </Space>
          )}
        />
        <Column
          title="Time"
          dataIndex="date_created"
          render={(text) => dayjs(text).format('MM-DD-YYYY hh:mm a')}
        />
      </Table>
    </>
  );
};

export default UserHistory;
