import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { DatePicker, Row, Divider, Skeleton } from 'antd';
import { IUserChart } from 'types';
import axios from 'utils/axios';
import moment, { Moment } from 'moment';

const transformData = (data: IUserChart) => {
  const days: Array<number> = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }
  const subscribed = Array(31).fill(0);
  const guests = Array(31).fill(0);
  data.subscribed.forEach(
    (datum: any) =>
      (subscribed[datum.days - 1] = subscribed[datum.days - 1] = datum.total),
  );
  data.guests.forEach((datum: any) => (guests[datum.days - 1] = datum.total));
  return days.map((d, i) => ({
    x: `Day-${d}`,
    subscribed: subscribed[i],
    guests: guests[i],
  }));
};

export default function MonthlySubGraph() {
  const year = new Date().getFullYear();
  const _month = new Date().getMonth() + 1;
  const initialMonth = `${year}-${_month}`;
  const [month, setMonth] = useState<string>(initialMonth);
  const [monthName, setMonthName] = useState<string>(
    moment().format('MMM, YYYY'),
  );
  const [chartData, setChartData] = useState<any | null>(null);

  const handleChange = (value: Moment | null, dateString: string) => {
    setMonthName(moment(value).format('MMM, YYYY'));
    if (dateString) {
      setMonth(dateString);
    }
  };
  useEffect(() => {
    const [_year, __month] = month.split('-');
    axios
      .get(
        `/analytics/user-chart?month=${Number(__month)}&year=${Number(_year)}`,
      )
      .then((response) => {
        if (response.status === 200) {
          setChartData(response.data.data[0]);
        }
      })
      .catch((error) => {});
  }, [month]);
  return (
    <Row className="chart-container">
      <Divider type="horizontal" orientation="center" plain>
        MONTHLY USERS CHART FOR {monthName}
      </Divider>

      <DatePicker
        picker="month"
        className="month-picker"
        onChange={handleChange}
      />
      {chartData ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={transformData(chartData)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="subscribed"
              stroke="#15e662"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="guests" stroke="#4b60db" />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Skeleton />
      )}
    </Row>
  );
}
