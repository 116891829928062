import React from 'react';
import { Statistic, Row, Col, Popover } from 'antd';
import { IBookStats, IBookRating } from 'types';

export default function BookStats({ data }: { data: IBookStats }) {
  const topRatedBooks = (rating: IBookRating[]) => {
    const topFive = rating.slice(0, 5);
    return (
      <div>
        {topFive.map((rate: IBookRating, index: number) => (
          <p key={index}>
            {rate.title}---{Number(rate.avg_rate)}
          </p>
        ))}
      </div>
    );
  };
  const leastRatedBooks = (rating: IBookRating[]) => {
    const topFive = rating.slice(rating.length - 5).reverse();
    return (
      <div>
        {topFive.map((rate: IBookRating, index: number) => (
          <p key={index}>
            {rate.title}---{Number(rate.avg_rate)}
          </p>
        ))}
      </div>
    );
  };

  return (
    <Row gutter={16}>
      <Col span={6}>
        <Statistic title="Active" value={data.active} />
      </Col>
      <Col span={6}>
        <Statistic title="Inactive" value={data.inactive} />
      </Col>
      <Col span={6}>
        <Popover content={`${data.most_read_count} reads`} placement="top">
          <Statistic title="Most Read" value={data.most_read} />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content={topRatedBooks(data.rating)} placement="top">
          <Statistic
            title="Most Rated"
            value={Number(data.rating[0].avg_rate)}
          />
        </Popover>
      </Col>
      <Col span={6}>
        <Popover content={leastRatedBooks(data.rating)} placement="top">
          <Statistic
            title="Least Rated"
            value={Number(data.rating[data.rating.length - 1].avg_rate)}
          />
        </Popover>
      </Col>
    </Row>
  );
}
