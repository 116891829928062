import {
  Button,
  Input,
  notification,
  Popover,
  Space,
  Modal,
  Select,
  Divider,
  Typography,
  Spin,
} from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

import config from 'config';
import React from 'react';
import { IUser } from 'types';
import axiosInstance from 'utils/axios';

const { Option } = Select;

export interface IUserActionProps {
  user: IUser | null;
  onUpdate(): void;
}

const UserActions = ({ user, onUpdate }: IUserActionProps) => {
  const [isRemoving, setRemoving] = React.useState(false);
  const [value, setValue] = React.useState<string>('');
  const [schools, setSchools] = React.useState([]);
  const [name, setName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [schoolId, setSchoolId] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [okLoading, setOkLoading] = React.useState(false);

  React.useEffect(() => {
    getSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  async function deleteUser(id: number) {
    if (user?.enabled) {
      setRemoving(true);
      try {
        await axiosInstance.delete(`${config.baseUrl}/users/delete/${id}`);
        onUpdate();
        openNotificationWithIcon({
          message: 'User deleted successfully',
          type: 'success',
        });
      } catch (error: any) {
        openNotificationWithIcon({
          type: 'error',
          message: error?.response?.data?.message,
        });
      } finally {
        setRemoving(false);
      }
    }
  }

  const makeVIP = async (id: number) => {
    if (user?.account_type === 0) {
      setRemoving(true);
      try {
        await axiosInstance.patch(`${config.baseUrl}/users/vip/${id}`);
        onUpdate();
        openNotificationWithIcon({
          message: 'User is now a VIP',
          type: 'success',
        });
      } catch (error: any) {
        openNotificationWithIcon({
          type: 'error',
          message: error?.response?.data?.message,
        });
      } finally {
        setRemoving(false);
      }
    }
  };

  const openNotificationWithIcon = ({
    type,
    message,
  }: {
    type: string;
    message: string;
  }) => {
    const value = type.includes('error') ? 'error' : 'success';
    notification[value]({
      message,
    });
  };

  const getSchools = async () => {
    try {
      const { data } = await axiosInstance.get(`${config.baseUrl}/schools`);
      setSchools(data.data);
    } catch (error: any) {
      openNotificationWithIcon({
        type: 'error',
        message: error?.response?.data?.message,
      });
    }
  };

  const promoteTeacher = async (id: number) => {
    try {
      setOkLoading(true);
      await axiosInstance.post(`${config.baseUrl}/schools/promote-teacher`, {
        user_id: Number(user?.id),
        school_id: id,
      });
      setOpen(false);
      onUpdate();
      openNotificationWithIcon({
        message: 'User upgraded to teacher',
        type: 'success',
      });
    } catch (error: any) {
      openNotificationWithIcon({
        type: 'error',
        message: error?.response?.data?.message,
      });
    } finally {
      setOkLoading(false);
    }
  };

  const createSchool = async () => {
    setLoading(true);
    try {
      const newSchool = await axiosInstance.post(`${config.baseUrl}/schools`, {
        name,
      });
      const { id } = newSchool.data.data[0];
      setSchoolId(id);
      setName('');
    } catch (error: any) {
      openNotificationWithIcon({
        type: 'error',
        message: error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const changeHandler = (event: any) => {
    setName(event.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      <Spin spinning={isLoading}>
        <br />
        <Space size="middle">
          <Popover
            trigger="click"
            content={() => (
              <Button
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  makeVIP(Number(user?.id));
                }}
                disabled={
                  isRemoving ||
                  user?.account_type === 1 ||
                  user?.account_type === 8
                }
                loading={isRemoving}
              >
                Confirm
              </Button>
            )}
            title="Make VIP"
          >
            <Button
              type="primary"
              size="small"
              disabled={
                isRemoving ||
                user?.account_type === 1 ||
                user?.account_type === 8
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Make VIP
            </Button>
          </Popover>
        </Space>

        <br />
        <br />
        <br />
        <Space size="middle">
          <Popover
            trigger="click"
            content={() => (
              <>
                <Input
                  placeholder="type DELETE"
                  onChange={(e) => setValue(e.target.value)}
                />
                <br />
                <Button
                  style={{ marginTop: '1rem' }}
                  danger
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteUser(Number(user?.id));
                  }}
                  disabled={isRemoving || !user?.enabled || value !== 'DELETE'}
                  loading={isRemoving}
                >
                  Confirm delete
                </Button>
              </>
            )}
            title="Delete user"
          >
            <Button
              danger
              size="small"
              disabled={isRemoving || !user?.enabled}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Delete User
            </Button>
          </Popover>
        </Space>

        <br />
        <br />
        <br />
        <Space size="middle">
          <Button
            size="small"
            disabled={
              isRemoving ||
              user?.roles?.some((role: any) => {
                return role.role === 'SCHOOL_TEACHER';
              }) ||
              user?.account_type === 1
            }
            color="green"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            Make Teacher
          </Button>
        </Space>

        <Modal
          title="Add teacher to a school"
          centered
          visible={open}
          maskClosable={false}
          onCancel={() => setOpen(false)}
          onOk={() => promoteTeacher(Number(schoolId))}
          cancelButtonProps={{ disabled: isLoading || okLoading }}
          okButtonProps={{
            disabled: isLoading || okLoading,
            loading: okLoading,
          }}
        >
          <Select
            style={{ width: 300 }}
            placeholder="School name"
            defaultValue={schoolId}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space align="center" style={{ padding: '0 8px 4px' }}>
                  <Input
                    placeholder="School name"
                    value={name}
                    onChange={changeHandler}
                  />
                  {isLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <Typography.Link
                      onClick={createSchool}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <PlusOutlined /> Add New School
                    </Typography.Link>
                  )}
                </Space>
              </>
            )}
            onChange={(value) => {
              setSchoolId(value.toString());
            }}
          >
            {schools.length &&
              schools.map((item: any) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Option>
              ))}
          </Select>
        </Modal>
      </Spin>
    </div>
  );
};

export default UserActions;
