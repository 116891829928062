import styled from 'styled-components';

export const StyledAuthLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #083261;

  .logo {
    width: 180px;
    padding: 1.5rem;

    img {
      width: 100%;
    }
  }

  .form-card {
    min-width: 25rem;
    border-radius: 4px;
    padding: 1.2rem;
    background-color: #fff;
  }
`;
