import React from 'react';
import { Popover, Table } from 'antd';
import { EditTwoTone, LoadingOutlined } from '@ant-design/icons';

import getBOTW from 'utils/getBOTW';

import ContentLayout from 'components/Layout';
import EditBOTW from './EditBOTW';

const BOTW = () => {
  const [id, setId] = React.useState<number>();
  const [botw, setBotw] = React.useState<any>([]);
  const [books, setBooks] = React.useState<any>([]);
  const [requesting, setRequesting] = React.useState<boolean>(false);

  React.useEffect(() => {
    getBOTW({ setRequesting, setBotw, setBooks });
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <ContentLayout title="Book Of The Week">
      <Table
        // pick data from botw if only books have been fetched
        dataSource={!!books.length ? botw : null}
        rowKey="id"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 10,
          total: botw ? botw.length : 0,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          showSizeChanger: false,
        }}
        loading={{ spinning: requesting, indicator: antIcon }}
      >
        <Table.Column title="Title" dataIndex="title" key="id" />
        <Table.Column title="From" dataIndex="start_date" key="id" />
        <Table.Column title="To" dataIndex="end_date" key="id" />
        <Table.Column
          title="Actions"
          render={() => (
            <div>
              <Popover
                content={
                  <EditBOTW
                    id={id}
                    books={books}
                    onFinish={() =>
                      getBOTW({ setRequesting, setBotw, setBooks })
                    }
                  />
                }
                title="Edit entry?"
                trigger="click"
              >
                <EditTwoTone />
              </Popover>
            </div>
          )}
          onCell={(record: any) => {
            return {
              onClick: () => setId(record.id),
            };
          }}
        />
      </Table>
    </ContentLayout>
  );
};

export default BOTW;
