import React from 'react';
import { Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { default as dayjs } from 'dayjs';
import { Link } from 'react-router-dom';

import { IUser } from 'types';

const { Column } = Table;

interface IUserBookChats {
  chats: any;
  getUserchats(page?: number): void;
}

const DisplayUserNameLink: React.FC<{ user: IUser }> = ({ user }) => {
  return (
    <Link to={`/dashboard/profile/${user?.id}`}>
      {`${user?.first_name} ${user?.last_name}`}
    </Link>
  );
};

const UserBookChats = ({ chats, getUserchats }: IUserBookChats) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Table
        dataSource={chats?.data}
        rowKey="id"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 10,
          total: chats ? chats.meta.total : 0,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} results`;
          },
          onChange: (page) => {
            getUserchats(page);
          },
          current: chats ? chats.meta.page : 1,
          showSizeChanger: false,
        }}
        loading={{ spinning: !chats, indicator: antIcon }}
      >
        <Column
          title="Room"
          dataIndex="bookchat"
          render={(bookchat) => {
            return bookchat.uuid;
          }}
        />

        <Column
          title="Invite Time"
          dataIndex="invite_time"
          render={(text) => dayjs(text).format('MM-DD-YYYY hh:mm a')}
        />

        <Column title="Response" dataIndex="status" />

        <Column
          title="Invitee"
          dataIndex="invitee"
          render={(invitee) => <DisplayUserNameLink user={invitee} />}
        />

        <Column
          title="Inviter"
          dataIndex="inviter"
          render={(inviter) => <DisplayUserNameLink user={inviter} />}
        />
      </Table>
    </>
  );
};

export default UserBookChats;
