import React from 'react';
import { Button } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import Select from './SelectBook';

export interface IProps {
  id?: number;
  books: { id: number; title: string }[];
  onFinish?: () => void;
}
const RemoveBooks = ({ id, books, onFinish }: IProps) => {
  const [requesting, setRequesting] = React.useState(false);
  const [bookIds, setBookIds] = React.useState<any[]>([]);

  const onChange = (values: number[]) => {
    setBookIds(values);
  };

  const clickHandler = async () => {
    setRequesting(true);
    try {
      await axiosInstance.patch(`${config.baseUrl}/bookshelves/${id}/books`, {
        bookIds,
      });
      return onFinish?.();
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  return books.length ? (
    <Select books={books} onChange={onChange}>
      <Button
        type="dashed"
        disabled={requesting}
        style={{ marginTop: '1rem' }}
        onClick={clickHandler}
      >
        Detach Books
      </Button>
    </Select>
  ) : null;
};

export default RemoveBooks;
