import React from 'react';
import { Button, Select, Modal, Form, message } from 'antd';
import config from 'config';
import axiosInstance from 'utils/axios';
import { GradeType } from './type';
import { IBookshelf } from 'types/bookshelf';
export interface IProps {
  bookshelves: IBookshelf[];
  onFinish?: () => void;
  grades: GradeType[];
}

const AttachGrade = ({ bookshelves, onFinish, grades }: IProps) => {

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const clickHandler = async (values: {
    bookshelfId: number;
    gradeId: number;
  }) => {
    try {
      await axiosInstance.patch(
        `${config.baseUrl}/schools/grades/attach-bookshelf`,
        values,
      );
      setIsModalVisible(false);
      message.success('Successfully attached bookshelf to grade');
      return onFinish?.();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Button type="ghost" onClick={showModal}>
        Attach Grade
      </Button>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Form form={form} onFinish={clickHandler} style={{ width: '100%' }}>
            <Form.Item
              name="gradeId"
              label="Grade"
              rules={[{ required: true, message: 'Please select grade' }]}
            >
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder="Select a grade"
                optionFilterProp="children"
                size="large"
              >
                {grades.map((grade) => (
                  <Select.Option key={grade.id} value={grade.id}>
                    {grade.name} - ({grade.code})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="bookshelfId"
              label="Bookshelf"
              rules={[{ required: true, message: 'Please select Bookshelf' }]}
            >
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder="Select a bookshelf"
                optionFilterProp="children"
                size="large"
              >
                {bookshelves.map((b) => (
                  <Select.Option key={b.id} value={b.id}>
                    {b.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AttachGrade;
