import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Radio } from 'antd';

import config from 'config';
import axiosInstance from 'utils/axios';
import getTags from 'utils/getTags';
import { ITag } from 'types';

import ContentLayout from 'components/Layout';
import TagTable from './TagTable';

const tagTypes: any = {
  category: 'Category',
  age: 'Age',
  curated: 'Featured',
};

const Tags = () => {
  const [tags, setTags] = useState<ITag[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [tagType, setTagType] = useState<string>('category');
  const [requesting, setRequesting] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');


  const toggleTagVisibility = async (tag: ITag) => {
    console.log({ tag })
    await axiosInstance.put(`${config.baseUrl}/tags/${tagType}/${tag.id}`, {
      hidden: !tag.hidden,
    });
    return getTags({ tagType, setTags, setRequesting });
  }
  useEffect(
    () => {
      getTags({ tagType, setTags, setRequesting });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagType],
  );

  const onChange = (e: any) => {
    setTagType(e.target.value);
  };

  const onFinish = async ({ title }: any) => {
    setRequesting(true);
    try {
      // create new tag
      await axiosInstance.post(`${config.baseUrl}/tags/${tagType}`, {
        name: title,
      });
      // fetch all tags
      await getTags({ tagType, setTags, setRequesting });
      return setVisible(false);
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error(errorInfo);
  };

  const modifyTag = async (
    id: number,
    shouldDelete: boolean,
    value?: string,
  ) => {
    setRequesting(true);
    try {
      shouldDelete
        ? // delete specified tag
          await axiosInstance.delete(`${config.baseUrl}/tags/${tagType}/${id}`)
        : // update specified tag
          await axiosInstance.put(`${config.baseUrl}/tags/${tagType}/${id}`, {
            value,
          });
      // reset value
      setValue('');
      // fetch all tags
      return getTags({ tagType, setTags, setRequesting });
    } catch (error) {
      setRequesting(false);
      console.error('error: ', error);
    }
  };

  return (
    <ContentLayout title={`Showing: All ${tagTypes[tagType]} tags`}>
      <div style={{ marginBottom: '1rem' }}>
        <Radio.Group onChange={onChange} value={tagType}>
          {Object.keys(tagTypes).map((tagType) => (
            <Radio value={tagType} key={tagType}>
              {tagTypes[tagType]}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      {!visible && (
        <Button
          type="primary"
          style={{ marginBottom: '1rem' }}
          onClick={() => setVisible(true)}
        >
          Add {tagTypes[tagType]} tag
        </Button>
      )}
      {visible && (
        <div
          style={{
            marginBottom: '1rem',
          }}
        >
          <Form
            {...{ wrapperCol: { span: 10 } }}
            layout="inline"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              {...{ wrapperCol: { span: 25 } }}
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter a title for the new category!',
                },
              ]}
            >
              <Input placeholder={`${tagTypes[tagType]} title`} />
            </Form.Item>
            <Form.Item {...{ wrapperCol: { offset: 2.5 } }}>
              <Button type="primary" htmlType="submit" disabled={requesting}>
                Create
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="default"
                disabled={requesting}
                onClick={() => setVisible(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <TagTable
        tags={tags}
        requesting={requesting}
        tagType={tagType}
        value={value}
        setValue={setValue}
        modifyTag={modifyTag}
        toggleTagVisibility={toggleTagVisibility}
      />
    </ContentLayout>
  );
};

export default Tags;
